.Challenge {
    display: block;
    min-height: 90vh;
    padding-top: 4rem;
    position: relative;
    width: 100%;
}

@media screen and (min-width: 960px) {
    .Challenge {
        padding-top: 8rem;
    }
}

.Challenge .in {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
}

.Challenge .masks,
.Challenge .portraits {
    background: var(--background--color);
    display: block;
    height: 40vh;
    margin: 0 auto;
    max-width: 160rem;
    position: relative;
    top: 4rem;
    z-index: 59;
}

.Challenge .masks {
    background: transparent;
    display: none;
}

.Challenge .masks .modi {
  margin-top: -1%;
}

.Challenge .masks .gandhi {
  margin-top: -1.5%;
}

@media screen and (min-width: 960px) {
    .Challenge .masks,
    .Challenge .portraits {
        background: transparent;
        display: block;
        position: sticky;
        top: 18rem;
    }
}

.Challenge .image {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 60vw;
    z-index: 1;
}

@media screen and (min-width: 960px) {
    .Challenge .image {
        width: 25%;
    }
}

@media screen and (min-width: 1200px) {
    .Challenge .image {
        width: 30%;
    }
}

.Challenge .image.gandhi {
    left: auto;
    right: 0;
}

.Challenge figure {
    display: block;
    height: 100%;
    margin: 0;
    width: auto;
}

.Challenge figure img {
    display: block;
    height: 100%;
    /* mix-blend-mode: multiply; */
    object-fit: contain;
    width: 100%;
}

.Challenge .image.modi .face {
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translate(-50%, 0)
}

.Challenge .image.gandhi .face {
    position: absolute;
    top: -6%;
    left: 54%;
    transform: translate(-50%, 0)
}

.Challenge .texts {
    margin-top: 6rem;
}

@media screen and (min-width: 960px) {
    .Challenge .texts {
        margin-top: -30vh;
    }
    .Challenge .texts.intro-text {
        margin-top: 0;
    }
    .Challenge .image.modi .face {
        top: 2%;
        left: 50%;
    }
    .Challenge .image.gandhi .face {
        top: -4%;
        left: 57%;
    }
}

.Challenge .intro p {
    font-family: 'Spectral', Serif;
    font-size: 2.2rem;
    /* font-style: italic; */
    font-weight: 800;
    /* text-align: center; */
    line-height: 3rem;
}

.Challenge .intro.stand-first p {
    font-family: 'Spectral', Serif;
    font-size: 2.2rem;
    font-weight: 200;
    /* text-align: center; */
    line-height: 3rem;
}

.Challenge .intro.stand-first p span[role="img"]{
  font-style: normal;
}

.Challenge .chart--intro h3 {
    text-align: center;
}

.Challenge .chart-wrapper {
    display: block;
    height: 4rem;
    margin: 2rem auto;
    position: relative;
    width: 90%;
}

@media screen and (min-width: 960px) {
    .Challenge .chart-wrapper {
        margin: 3rem auto;
        width: 40%;
    }
}

.Challenge .chart-wrapper:last-child {
    margin-bottom: 0;
}

@media screen and (min-width: 960px) {
    .Challenge h1 {
        padding: 6rem 0;
    }
}

.Challenge #head2Head {
    position: sticky;
    top: calc(-100vh + 18rem);
    z-index: 999;
}

.Challenge #head2Head .face {
    position: absolute;
    top: 100vh;
}

.Challenge #head2Head .face.modi {
    left: 0;
    transform: translate(-50%, 0)
}

.Challenge #head2Head .face.rahul {
    right: 0;
    transform: translate(-50%, 0)
}
