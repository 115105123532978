.OverviewStreamGraph {
  margin-top: 8rem;
}

@media screen and (min-width: 960px) {
  .OverviewStreamGraph {
    margin-top: 15rem;
  }
}

.OverviewStreamGraph .chart--wrapper {
  background-color: var(--background--color);
}

@media screen and (min-width: 960px) {
  .OverviewStreamGraph .chart--wrapper {
    position: relative;
    top: 0;
    z-index: 2;
  }
}

.OverviewStreamGraph figure {
  margin: 0;
  padding-top: 1rem;
}

@media screen and (min-width: 960px) {
  .OverviewStreamGraph figure {
    padding-top: 1rem;
  }
}

.OverviewStreamGraph .figcaption {
  position: relative;
  text-align: center;
}

.OverviewStreamGraph .OverviewStreamGraph--wrapper {
  box-shadow: inset 0 4rem 4.2rem -4.2rem rgba(0, 0, 0, 0.1),
    inset 0 -4rem 4.2rem -4.2rem rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 25rem;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .OverviewStreamGraph .OverviewStreamGraph--wrapper {
    height: 30rem;
  }
}

@media screen and (min-width: 1200px) {
  .OverviewStreamGraph .OverviewStreamGraph--wrapper {
    height: 35rem;
  }
}
