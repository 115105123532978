.Header {
  background: var(--background--color);
  border-bottom: .1rem solid var(--text--color);
  display: block;
  height: 4rem;
  left: 0;
  margin: 0;
  overflow: visible;
  right: 0;
  top: 0;
  width: auto;
  z-index: 99;
}

@media screen and (min-width: 960px) {
  .Header {
    /* background: transparent; */
    border-bottom: none;
    height: 10rem;
  }

  .Header--wrapper {
    margin: 0 auto 0 auto;
    max-width: 160rem;
    width: 85%;
  }
}

.Header h1 {
  font-family: 'Spectral', Serif;
  font-size: 2rem;
  font-weight: 800;
  line-height: 4rem;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
  text-align: center;
}

.Header h1 .wheel {
  display: inline-block;
  height: 1.4rem;
  margin: 0 0 0 .1rem;
  vertical-align: baseline;
  width: 1.4rem;
}

.Header h1 .wheel img {
  display: inline-block;
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.Header h2 {
  display: none;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 3rem;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (min-width: 960px) {
  .Header h1 {
    font-size: 4.6rem;
    line-height: 4.8rem;
    margin-top: 1rem;
    text-align: center;
  }

  .Header h1 .wheel {
    height:3.2rem;
    margin-right: 0;
    /* mix-blend-mode: multiply; */
    width:3.2rem;
  }

  .Header h2 {
    display: block;
  }
}
