@mixin shadow() {
  text-shadow: var(--background--color) 1px 0 0,
    var(--background--color) 0.540302px 0.841471px 0,
    var(--background--color) -0.416147px 0.909297px 0,
    var(--background--color) -0.989992px 0.14112px 0,
    var(--background--color) -0.653644px -0.756802px 0,
    var(--background--color) 0.283662px -0.958924px 0,
    var(--background--color) 0.96017px -0.279415px 0;
}

.top-news {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  .top-news-container {
    >div {
      position: absolute;
      transform: translateX(-50%);
      height: 100%;

      &:hover {
        background-color: rgba(0,0,0,0);

        article,
        span.top-news-arrow {
          display: block;
        }
      }

      article {
        position: absolute;
        top: -10px;
        transform: translate(-50%, -100%);
        font-size: 1.1rem;
        line-height: 1.2rem;
        // height: 7.5rem;
        // max-width: 17.5rem;
        width: 200px;
        position: absolute;
        text-align: center;
        // -webkit-transform: translate3d(-50%,0,0);
        // transform: translate3d(-50%,0,0);
        display: none;
      }

      span.top-news-arrow {
        position: absolute;
        width: 1px;
        left: 50%;
        background-color: #666;
        transform: translateX(-50%);
        display: none;
      }
    }
  }
  .story-news {
    width: 100%;

    position: absolute;
    .story-news-container {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: flex-end;
      // align-items: stretch;
      padding-right: 80px;

      article {
        width: calc(100% - 75px);
        display: none;

        @include shadow();

        &:first-child {
          display: block;
        }

        @media screen and (min-width: 768px) {
          display: block;
          width: 40%;
          padding-top:0;
          padding-bottom: 0;
          position: relative;
          left: unset;
          right: 75px;
          padding-right: 2rem;
        }
        text-align: left;
        // margin-left: 2rem;
        // padding-left: 2rem;
        h4 {
          font-size: 1.1rem;
          text-transform: uppercase;
          font-weight: 300;
          margin: 0;
          margin-top: 0.2rem;
        }
        h5 {
          font-size: 1.1rem;
          text-transform: uppercase;
          font-weight: 300;
          margin: 0;
          margin-top: 0.2rem;
        }
        h2 {
          font-size: 1.6rem;
          line-height: 1.8rem;
          text-align: left;
          margin-top: 0.5rem;

          @media screen and (min-width: 960px) {
            font-size: 2.2rem;
            line-height: 2.4rem;
          }
        }

        p {
          font-size: 1.3rem;
          margin-top: 0.2rem;
        }

      }
    }

  }
}
