.tile-map {
  position: relative;
  width: calc(100% - 2rem);
  margin-left: 2rem;



  flex-direction: column;
  //height: 100%;

  .row {
    width: 100%;
    height: calc(100% / 9);
    display: flex;
    flex-basis: calc(100% / 9);

    .cell {
      width: calc(100% / 9);
      height: 100%;
      position: relative;

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }
  }

  @media screen and (min-width: 568px) {
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  .tile-key {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    &:before {
      content: 'Key';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      font-size: 11px;
      transform: translateY(-100%);
    }
    ul.keys {
      position: absolute;
      top: 0;
      left: 0px;
      transform: translateX(-100%);
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        font-size: 10px;
        text-align: right;
        height: 25%;
        line-height: 12.5px;
        padding-right: 0.2rem;
      }
    }
  }
  .tile {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 2px solid transparent;
    outline-color: transparent;
    outline-style: none;
    cursor: pointer;

    &[rel='delhi'] {
      border: 2px solid #000;
    }
    &:hover {
        border-color: #000;
        border-top-color: var(--background--color);
        z-index: 9999;

        .state-name {
          display: inline-block;
        }
    }
    .state-name {
      position: absolute;
      display: none;
      font-size: 12px;
      line-height: 16px;
      background-color: var(--background--color);
      transform: translate(-2px,-100%);
      top: 2px;
      text-transform: capitalize;
      pointer-events: none;
      white-space: nowrap;
      border: 2px solid #000;
      min-width: calc(100% + 4px);
      z-index: 9;
      padding: 3px;
    }
    .chart-container {
      background-color: #f9f2ca;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 90;
    }

    ul.slices {
      list-style: none;
      margin: 0;
      position: relative;
      height: 100%;
      padding: 0;

      li {
        position: absolute;
        width: 100%;

        &.yogi,
        &.modi,
        &.nda {
          background-color: var(--nda--color);
        }

        &.gandhi,
        &.upa {
          background-color: var(--upa--color);
        }

        &.mayawati,
        &.yadav,
        &.mahagathbandhan, {
          background-color: var(--mayawati--color);
        }

        &.banerjee,
        &.others {
          background-color: var(--others--color);
        }

        &.kejriwal {
          background-color: var(--aap--color);
        }
      }
    }
  }

  .annotation {
    p.text {
      width: auto;
      min-width: 70px;
    }
  }
}
