.annotation {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 15px;
  @media (min-width: 768px) {
    width: 25px;
    height: 25px;
    z-index: 9;
  }

  transform: translate(-100%, -100%);
  p.text {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    padding-bottom: 3px;
    width: 120px;
    @media (min-width: 768px) {
      // width: 150px;
    }
    transform: translate(-100%, -100%);
    text-align: right;

    &.centered {
      transform: translate(-50%, -100%);
    }

    b {
      display: inline-block;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #000;
      color: #fff;
      padding: 1px;
      text-align: center;
      line-height: 9px;
      font-weight: 900;
    }
  }
  .arrow {
    width: inherit;
    height: inherit;
    position: relative;
    overflow: hidden;
    span {
      position: absolute;
      border: 1px solid #888;
      border-radius: 50%;
      width: 200%;
      height: 200%;
      left: 0;
      bottom: 0;
    }
  }
  &.straight {
    transform: translate(-100%, 0);
    p.text {
      transform: translate(-100%, -50%);
      padding-right: 5px;
    }
    .arrow {
      overflow: visible;
      span {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        border-top: 1px solid #888;
      }
    }
  }
  &.deg45 {
    .arrow {
      overflow: visible;
      transform: translate(10%, 30%) rotate(-45deg);
      transform-origin: 50%;
      width: 140%;
      span {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        border-top: 1px solid #888;
      }
    }
  }
  &.no-arrow {
    transform: translate(-50%, 0%);
    p.text {
      left: 50%;
      transform: translate(-50%, -70%);
      padding-right: 0;
      padding-left: 0;
      text-align: left;

      h4 {
        font-size: inherit;
        font-family: inherit;
        margin: 0;
        padding: 0;
      }
    }
    &.right {
      p.text {
        transform: translate(0%, -100%);
      }
    }
    .arrow {
      display: none;
    }
  }
  &.left-to-right {
    &.long {
      &.straight {
        p.text {
          left: -200%;
        }
        .arrow {
          span {
            width: 300%;
            left: -200%;
          }
        }
      }
    }
  }
  &.right-to-left {
    transform: translate(0, -100%);

    p.text {
      transform: translate(0, -100%);
      text-align: left;
      left: 100%;

      &.centered {
        text-align: center;
        transform: translate(-50%, -100%);
      }
    }
    .arrow {
      span {
        left: auto;
        right: 0;
      }
    }

    &.straight {
      transform: translate(0, 0);
      p.text {
        transform: translate(0, -50%);
        padding-left: 5px;
      }
    }

    &.45deg {
      transform: translate(0, 0);
    }
  }
  &.top-to-bottom {
    transform: translate(-50%, -100%);
  }
  &.bottom-to-top {
    transform: translate(-50%, -100%);

    p.text {
      transform: translate(-50%, -100%);
      text-align: center;
      left: 50%;
    }
    .arrow {
      span {
        left: -1px;
        transform: translate(50%,0);
        width: 0;
        height: 100%;
        border-top: none;
        border-left: 1px solid #888;
      }
    }

    &.straight {
      transform: translate(0, 0);
      p.text {
        transform: translate(-50%, 0);
        padding: 0;
        padding-top: 5px;
        left: 0;
        top: 100%;
      }
      &.left-aligned {
        p.text {
          transform: translate(-10%, 0);
          text-align: left;
        }
      }
    }

    &.long {
      &.straight {
        p.text {
          top: 200%;
        }
        .arrow {
          span {
            height: 200%;
            top: 0;
          }
        }
      }
    }

    &.very-long {
      &.straight {
        p.text {
          top: 300%;
        }
        .arrow {
          span {
            height: 300%;
            top: 0;
          }
        }
      }
    }

  }
}
