.starring {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {

      height: 100%;
      margin-bottom: 3rem;



      .character-title {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        svg {
          min-width: 100px;
        }
        h2 {
          font-size: 2.4rem;
          line-height: 2.6rem;
          margin: 0;
          text-align: left;
        }
      }
      @media screen and (min-width: 750px) {
        .character-description {
          padding-left: 100px;
        }
      }
    }
  }
}
