    .StreamGraph {
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }

    .StreamGraph svg .line {
        /* mix-blend-mode: multiply; */
        stroke: rgba(68, 68, 68, 0.6);
        stroke-dasharray: 1 2;
        stroke-width: 1px;
    }

    .StreamGraph svg .bullet {
        /* mix-blend-mode: multiply; */
        stroke: rgba(68, 68, 68, 0.6);
        stroke-width: 1px;
    }

    .StreamGraph svg .label {
        fill: rgba(68, 68, 68, 1);
        font-size: 10px;
    }

    .StreamGraph svg .legend {
        fill: rgba(68, 68, 68, 1);
        font-size: 10px;
        text-transform: uppercase;
    }

    .StreamGraph svg .area.upa {
        fill: var(--upa--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .area.mayawati {
        fill: var(--mayawati--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .area.nda {
        fill: var(--nda--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .area.others {
        fill: var(--others--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .circle.upa {
        fill: var(--upa--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .circle.mayawati {
        fill: var(--mayawati--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .circle.nda {
        fill: var(--nda--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .circle.others {
        fill: var(--others--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .dynamic-labels text {
        fill: rgb(68, 68, 68);
        font-size: 14px;
        paint-order: stroke;
        stroke: var(--background--color);
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        font-weight: 400;
        text-transform: uppercase;
        text-anchor: middle;
    }

    .StreamGraph .legend {
        left: 1rem;
        position: absolute;
        top: 1rem;
        z-index: 2;
    }

    @media screen and (min-width: 960px) {
        .StreamGraph .legend {
            bottom: 1rem;
            left: 50%;
            top: auto;
            transform: translate3d(-50%, 0, 0);
        }
    }

    .StreamGraph .legend ul,
    .StreamGraph .legend li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .StreamGraph .legend li {
        font-size: 1rem;
        height: 2rem;
        line-height: 2rem;
        position: relative;
        text-transform: uppercase;
    }

    @media screen and (min-width: 960px) {
        .StreamGraph .legend li {
            display: inline-block;
            padding: 0 1rem;
        }
    }

    .StreamGraph .legend .circle {
        display: block;
        height: 2rem;
        line-height: 2rem;
        padding-left: 2rem;
        position: relative;
    }

    .StreamGraph .legend .circle:before {
        background: #eee;
        border-radius: 50%;
        content: '';
        display: block;
        height: 1.2rem;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        width: 1.2rem;
    }

    .StreamGraph .legend .circle.upa:before {
        background: var(--upa--color);
    }

    .StreamGraph .legend .circle.nda:before {
        background: var(--nda--color);
    }

    .StreamGraph .legend .circle.mayawati:before {
        background: var(--mayawati--color);
    }

    .StreamGraph .legend .circle.others:before {
        background: var(--others--color);
    }
