$destra: #1E7AF7;
$centro-destra: #56CCF2;
$centro-sinistra: #F55B5B;
$sinistra: rgba(186,0,78,1);
$m5s: #FFDC73;

$nda: var(--nda--color);
$upa: var(--upa--color);
$mahagathbandhan: var(--mahagathbandhan--color);
$mahagathbandhan-yadav: var(--mahagathbandhan--color);
$mahagathbandhan-mayawati: var(--mahagathbandhan--color);
$others: var(--others--color);

$paddingTop: 0;//calc(100vh - 200px);

// @mixin shadow() {
//   text-shadow: rgb(255, 255, 255) 1px 0 0, rgb(255, 255, 255) 0.540302px 0.841471px 0,
//     rgb(255, 255, 255) -0.416147px 0.909297px 0, rgb(255, 255, 255) -0.989992px 0.14112px 0,
//     rgb(255, 255, 255) -0.653644px -0.756802px 0, rgb(255, 255, 255) 0.283662px -0.958924px 0,
//     rgb(255, 255, 255) 0.96017px -0.279415px 0;
// }

@mixin shadow() {
  text-shadow: var(--background--color) 1px 0 0,
    var(--background--color) 0.540302px 0.841471px 0,
    var(--background--color) -0.416147px 0.909297px 0,
    var(--background--color) -0.989992px 0.14112px 0,
    var(--background--color) -0.653644px -0.756802px 0,
    var(--background--color) 0.283662px -0.958924px 0,
    var(--background--color) 0.96017px -0.279415px 0;
}

@mixin shadowAlpha() {
  text-shadow: var(--background--color-alpha) 1px 0 0,
    var(--background--color-alpha) 0.540302px 0.841471px 0,
    var(--background--color-alpha) -0.416147px 0.909297px 0,
    var(--background--color-alpha) -0.989992px 0.14112px 0,
    var(--background--color-alpha) -0.653644px -0.756802px 0,
    var(--background--color-alpha) 0.283662px -0.958924px 0,
    var(--background--color-alpha) 0.96017px -0.279415px 0;
}



@mixin facebook() {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iLTIgLTIgMzIgMzIiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xNy45IDE0aC0zdjhIMTJ2LThoLTJ2LTIuOWgyVjguN0MxMiA2LjggMTMuMSA1IDE2IDVjMS4yIDAgMiAuMSAyIC4xdjNoLTEuOGMtMSAwLTEuMi41LTEuMiAxLjN2MS44aDNsLS4xIDIuOHoiLz48L3N2Zz4=);
}
@mixin twitter() {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iLTIgLTIgMzIgMzIiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yMS4zIDEwLjV2LjVjMCA0LjctMy41IDEwLjEtOS45IDEwLjEtMiAwLTMuOC0uNi01LjMtMS42LjMgMCAuNi4xLjguMSAxLjYgMCAzLjEtLjYgNC4zLTEuNS0xLjUgMC0yLjgtMS0zLjMtMi40LjIgMCAuNC4xLjcuMWwuOS0uMWMtMS42LS4zLTIuOC0xLjgtMi44LTMuNS41LjMgMSAuNCAxLjYuNC0uOS0uNi0xLjYtMS43LTEuNi0yLjkgMC0uNi4yLTEuMy41LTEuOCAxLjcgMi4xIDQuMyAzLjYgNy4yIDMuNy0uMS0uMy0uMS0uNS0uMS0uOCAwLTIgMS42LTMuNSAzLjUtMy41IDEgMCAxLjkuNCAyLjUgMS4xLjgtLjEgMS41LS40IDIuMi0uOC0uMy44LS44IDEuNS0xLjUgMS45LjctLjEgMS40LS4zIDItLjUtLjQuNC0xIDEtMS43IDEuNXoiLz48L3N2Zz4=);
}
