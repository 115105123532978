@import '../../variables.scss';

ul.article-texts {
  margin: 0;
  margin-left: 50%;
  margin-top: $paddingTop;

  .annotation {
    p.text {
      color: #b1a979;
      font-style: italic;
      font-size: 10px;
      line-height: 12px;
      padding-bottom: 0;
    }
    div.arrow > span {
      border-top-color: #b1a979;
    }
  }
  @media (min-width: 768px) {
    margin-top: $paddingTop;

    .annotation p.text {
      width: 120px;
    }
  }

  width: 100%;
  position: absolute;
  list-style: none;
  top: 0;
  z-index: 999999;
  // pointer-events: none;

  li.article-text {
    position: absolute;
    background-color: transparent;
    overflow: visible;
    width: 100%;
    bottom: 0;
    @media (min-width: 768px) {
      max-width: 350px;
      bottom: auto;
      // width: 40%;
    }
    @media screen and (min-width: 992px) {
      // width: 25%;
    }
    transform: translateY(-50%);

    @include shadow();

    left: -50%;
    padding: 0 10px;
    margin-top: -5px;

    > article {
      border-top: 3px solid #000;
      border-bottom: 1px solid #000;
      padding: 10px 0;
      background-color: rgba(255, 255, 255, 0.9);
      position: relative;

      a.read-more {
        // font-family: 'Nunito Sans',sans-serif;
        font-weight: 300;
        font-size: 11px;
        text-decoration: none;
        color: #000;
        display: inline-block;
        position: absolute;
        top: 12px;
        right: 10px;
        line-height: 1rem;
        text-transform: uppercase;

        &:hover {
          background-color: transprent;
        }
      }
    }

    @media (min-width: 576px) {
      padding: 0;
      margin-top: 0;

      > article {
        border-top: none;
        border-bottom: none;
        padding: 0;
      }

      > article a.read-more {
        display: none;
      }

      &::after {
        content: '';
        width: 20%;
        height: 1px;
        position: absolute;
        background-color: #000;
        top: 50%;
      }

      &.small {
        overflow: visible;
        &::after {
          display: none;
        }
      }

      &.left-align {
        text-align: left;
        > article {
          margin-left: 20%;
          border-left: 1px solid #000;
          padding-left: 10px;
        }
        ul.article-slugs {
          justify-content: flex-start;
          li {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }
      &.right-align {
        text-align: right;
        transform: translate(-100%, -50%);
        &::after {
          right: 0;
        }
        > article {
          margin-right: 20%;
          border-right: 1px solid #000;
          padding-right: 10px;

          h2 {
            text-align: right;
          }
        }
      }

      ul.article-tags {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        li {
          padding: 0;
          margin: 0;
          text-align: left;
          margin-left: 1rem;
        }
      }
    }
    ul.article-slugs {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      // font-family: 'Nunito Sans',sans-serif;
      font-weight: 300;
      font-size: 11px;
      margin: 0;
      margin-top: 1rem;
      padding: 0;
      @media (min-width: 576px) {
        justify-content: flex-end;
      }
      li {
        padding: 0;
        padding-left: 1rem;
        margin: 0;
        text-align: left;
        margin-left: 1rem;
        text-transform: uppercase;
        white-space: nowrap;
        position: relative;

        &::before {
          content: '';
          width: calc(1rem / 1.41);
          height: calc(1rem / 1.41);
          display: inline-block;
          position: absolute;
          background: #000;
          transform-origin: 0 0;
          transform: rotate(45deg);
          top: 2px;
          left: 0;
        }
        &.sinistra::before {
          background-color: $sinistra;
        }
        &.centro-sinistra::before {
          background-color: $centro-sinistra;
        }
        &.m5s::before {
          background-color: $m5s;
        }
        &.centro-destra::before {
          background-color: $centro-destra;
        }
        &.destra::before {
          background-color: $destra;
        }
        &.upa::before {
          background-color: $upa;
        }
        &.nda::before {
          background-color: $nda;
        }
        &.others::before {
          background-color: $others;
        }
        &.mahagathbandhan::before {
          background-color: $mahagathbandhan;
        }
        &.mahagathbandhan-yadav::before {
          background-color: $mahagathbandhan-yadav;
        }
        &.mahagathbandhan-mayawati::before {
          background-color: $mahagathbandhan-mayawati;
        }
      }
    }
    h2 {
      margin: 0;
      padding: 0;
      display: block;
      position: relative;
      font-family: 'Spectral', Serif;
      font-size: 1.6rem;
      line-height: 1.7rem;
      font-weight: normal;
      color: #222;
      margin-top: 10px;
      text-align: left;

      a {
        display: inline;
        text-decoration: none;
        color: inherit;
        pointer-events: none;
        background-color: transparent;
      }
      a:hover {
        text-decoration: underline;
        background-color: transparent;
      }

      @media (min-width: 768px) {
        font-size: 1.9rem;
        line-height: 2rem;
        font-weight: bold;

        a {
          pointer-events: auto;
        }
      }
    }
    span {
      // font-family: 'Nunito Sans',sans-serif;
      font-weight: 300;
      display: block;
      font-size: 11px;
    }
    span.article-source {
      text-transform: uppercase;
    }

    &.hidden {
      article {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    &.default,
    &.highlight-article {
      h2 {
        color: #000;
      }
    }

    //@media screen and (min-width: 750px) {
    &.default {
      > article {
        display: block !important;
        background-color: rgba(255, 252, 235, 0.9);
      }
      &::after {
        display: block !important;
      }
    }
    &.highlight-article {
      z-index: 9999;
      > article {
        display: block !important;
        background-color: rgba(255, 252, 235, 0.9);
      }
      &::after {
        display: block !important;
      }
    }
    //}
  }
}
