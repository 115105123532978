.Footer {
    background: var(--text--color);
    color: var(--background--color);
    display: block;
    margin: 0;
    padding: 4rem 2rem;
}

.Footer .Footer--wrapper {
    display: block;
    margin: 0 2rem;
    max-width: 160rem;
    position: relative;
    width: auto;
}

.India .Footer--wrapper .col {
    margin-top: 2rem;
}

@media screen and (min-width: 960px) {
    .India .Footer {
        padding: 4rem 0;
    }
    .India .Footer--wrapper {
        align-content: stretch;
        align-items: stretch;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0 2rem;
        width: 100%;
    }
    .India .Footer--wrapper .col {
        display: block;
        flex: 0 0 30%;
        margin-top: 0;
        max-width: 30%;
    }
    .India .Footer--wrapper .col:last-child {
        flex: 0 0 60%;
        max-width: 60%;
    }
}

.Footer p {
    font-size: 1.2rem;
    margin-top: 2rem;
}

.Footer p:first-child {
    margin-top: 0;
}

.Footer h3 {
    font-size: 1.6rem;
}

.Footer h3:first-child,
.Footer h3+p {
    margin-top: 0;
}

.Footer h3 a {
    color: var(--background--color);
    text-decoration: none;
}

.Footer h3 a:hover,
.Footer h3 a:active {
    background: transparent;
    color: var(--background--color);
    text-decoration: none;
}

.Footer img {
    height: 3rem;
    object-fit: contain;
    vertical-align: middle;
    width: 3rem;
}

.Footer a {
    color: var(--background--color);
    text-decoration: underline;
}

.Footer a:hover,
.Footer a:active {
    background: transparent;
}
