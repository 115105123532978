.LollipopChart {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    margin: 0 auto;
}

.LollipopChart .right-wrapper,
.LollipopChart .left-wrapper {
    bottom: auto;
    height: 4rem;
    left: 0;
    position: absolute;
    right: 50%;
    top: 0;
    width: auto;
    transition: transform 1s;
}

.LollipopChart .left-wrapper {
    transform-origin: 100% 50%;
}

.LollipopChart .right-wrapper {
    left: 50%;
    right: 0;
    transform-origin: 0 50%;
}

.LollipopChart.vertical .left-wrapper {
    transform: rotate(-90deg);
}

.LollipopChart.vertical .right-wrapper {
    transform: rotate(90deg);
}

.LollipopChart .right-wrapper .in:after,
.LollipopChart .left-wrapper .in:after {
    background: var(--shadow--color);
    content: '';
    display: none;
    height: 3rem;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: .1rem;
}

.LollipopChart .left-wrapper .in:after {
    right: 0;
    left: auto;
}

.LollipopChart .in {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
}

.LollipopChart .in.w-separator:after {
    background: rgba(0, 0, 0, .15);
    bottom: .7rem;
    content: '';
    display: block;
    height: auto;
    /* mix-blend-mode: multiply; */ /* it breaks the faces */
    position: absolute;
    right: -.1rem;
    top: .7rem;
    transform: translate3d(0, 0, 0);
    width: .2rem;
}

.LollipopChart .line {
    /* background: var(--text--color); */
    background: transparent;
    border-bottom: .2rem dotted rgba(0, 0, 0, .15);
    display: block;
    /* height: .4rem; */
    height: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: .1rem;
}

.LollipopChart .bullet {
    background: var(--text--color);
    border: .4rem solid var(--text--color);
    border-radius: .2rem;
    display: block;
    height: 1.8rem;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0) rotate(45deg);
    width: 1.8rem;
}

.LollipopChart .legend {
    bottom: 0;
    height: 2rem;
    left: 0;
    line-height: 2rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: 4rem;
    width: auto;
}

.LollipopChart .legend .item {
    font-size: 1.2rem;
    margin: 0 1.5rem;
    padding-left: 1.6rem;
    position: relative;
    text-transform: uppercase;
}

.LollipopChart .legend .item:before {
    content: '';
    border-radius: 50%;
    display: block;
    height: 1rem;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 1rem;
}

.LollipopChart .legend .item.nda:before,
.LollipopChart .legend .item.modi:before {
    background-color: var(--nda--color);
}

.LollipopChart .legend .item.upa:before,
.LollipopChart .legend .item.gandhi:before {
    background-color: var(--upa--color);
}

.LollipopChart .nda .bullet,
.LollipopChart .modi .bullet {
    background-color: var(--nda--color);
    border-color: var(--nda--color);
}

.LollipopChart .upa .bullet,
.LollipopChart .gandhi .bullet {
    background-color: var(--upa--color);
    border-color: var(--upa--color);
}

.LollipopChart .nda .line,
.LollipopChart .modi .line {
    border-color: var(--nda--color);
}

.LollipopChart .upa .line,
.LollipopChart .gandhi .line {
    border-color: var(--upa--color);
}

.LollipopChart .label {
    display: inline-block;
    font-size: 1.1rem;
    line-height: 1;
    padding: 0 3rem;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: auto;
    white-space: nowrap;
}
.LollipopChart .label b {
  font-size: 2rem;
  font-weight: 200;
  display: block;
  text-align: center;
}

.LollipopChart .right-wrapper .label {
    padding: 0 0 0 3rem;
    text-align: left;
}

.LollipopChart .left-wrapper .label {
    padding: 0 3rem 0 0;
    text-align: right;
}

.LollipopChart.vertical .right-wrapper .label {
    transform-origin: 0 0%;
    transform: rotate(-90deg);
}

.LollipopChart.vertical .left-wrapper .label {
    transform-origin: 100% 0%;
    transform: rotate(90deg);
}
