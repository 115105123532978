.India {
    display: block;
    height: auto;
    margin: 0 auto;
    padding: 0;
    position: relative;
    width: 100%;
}

.India .component-wrapper {
    display: block;
    margin: 4rem 2rem 0 2rem;
    position: relative;
    width: auto;
}

@media screen and (min-width: 960px) {
    .India .component-wrapper {
        margin: 6rem auto;
        max-width: 1280px;
        width: 85%;
    }
}

.India .highlight {
    color: var(--highlight--color);
}

.India .cols {
    columns: 1;
    margin-top: 4rem;
}

@media screen and (min-width: 760px) {
    .India .cols {
        columns: 1;
        margin: 2rem auto 2rem;
        width: 75%;
    }
}

@media screen and (min-width: 960px) {
    .India .cols {
        columns: 1;
        margin: 3rem auto 3rem;
        width: 50%;
    }
}

@media screen and (min-width: 1200px) {
    .India .cols {
        width: 40%;
    }
}

.India h1 {
    font-family: 'Spectral', Serif;
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 1.15;
    letter-spacing: 0px;
    margin: 2rem 0 0 0;
    padding: 0;
    text-align: center;
}

.India h2 {
    font-family: 'Spectral', Serif;
    font-size: 2.8rem;
    font-weight: 900;
    line-height: 4rem;
    letter-spacing: normal;
    margin: 2rem 0 0 0;
    padding: 0;
    text-align: center;
}

@media screen and (min-width: 960px) {
    .India h1 {
        font-size: 5rem;
        margin-top: 4rem;
    }
    .India h2 {
        font-size: 4.4rem;
        margin-top: 4rem;
    }
}

.India h3 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    margin: 2rem 0 0 0;
    text-transform: uppercase;
}

.India p+h3 {
    margin-top: 4rem;
}

span.legend-nda {
    box-shadow: inset 0 -0.35rem 0 0 var(--nda--color);
    font-weight: 700;
    /* mix-blend-mode: multiply; */
}

span.legend-mahagathbandhan,
span.legend-mayawati {
    box-shadow: inset 0 -0.35rem 0 0 var(--mayawati--color);
    font-weight: 700;
    /* mix-blend-mode: multiply; */
}

span.legend-upa {
    box-shadow: inset 0 -0.35rem 0 0 var(--upa--color);
    font-weight: 700;
    /* mix-blend-mode: multiply; */
}

span.legend-others {
    box-shadow: inset 0 -0.35rem 0 0 var(--others--color);
    font-weight: 700;
    /* mix-blend-mode: multiply; */
}
