:root {
  --background--color: rgb(255, 252, 235);
  --background--color-alpha: rgb(255, 252, 235, 0.75);
  --text--color: rgb(68, 68, 68);
  --link--color: rgb(97, 84, 136);
  --highlight--color: rgb(229, 111, 135);
  --upa--color: rgb(47, 144, 177);
  --mayawati--color: rgb(102, 169, 49);
  --mahagathbandhan--color: rgb(102, 169, 49);
  --nda--color: rgb(255, 187, 12);
  --others--color: rgb(162, 117, 171);
  --aap--color: rgb(107, 233, 255);
  --shadow--color: rgba(68, 68, 68, 0.2);
}

html {
  font-size: 62.5%;
}

body {
  background: var(--background--color);
  border: 0;
  color: var(--text--color);
  font-family: 'Work Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--link--color);
  padding: 0 0.2rem;
}

a:active,
a:hover {
  background-color: var(--link--color);
  color: var(--background--color);
  text-decoration: none;
}

p {
  margin: 2rem 0 0 0;
}

p:first-child {
  margin-top: 0;
}
