.Ridgeline {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.Ridgeline svg .line {
  fill: none;
  /* mix-blend-mode: multiply; */
  opacity: .2;
  stroke: rgb(68, 68, 68);
  stroke-dasharray: none;
  stroke-width: 1px;
}

.Ridgeline svg .serie path.area {
  fill: red;
  /* mix-blend-mode: multiply; */
  opacity: .25;
  stroke: none;
  stroke-dasharray: none;
  stroke-width: 0;
}

.Ridgeline svg .serie path.line {
  fill: none;
  opacity: .85;
  stroke: red;
  stroke-dasharray: none;
  stroke-width: .1rem;
}

.Ridgeline svg .serie text {
  font-size: 1rem;
  text-transform: uppercase;
}

.Ridgeline svg .serie line {
  stroke: rgba(0, 0, 0, .25);
  stroke-width: .1rem;
}

.Ridgeline svg .serie.nda path.area,
.Ridgeline svg .serie.yogi path.area,
.Ridgeline svg .serie.modi path.area {
  fill: var(--nda--color);
}

.Ridgeline svg .serie.upa path.area,
.Ridgeline svg .serie.gandhi path.area {
  fill: var(--upa--color);
}

.Ridgeline svg .serie.mayawati path.area {
  fill: var(--mayawati--color);
}

.Ridgeline svg .serie.yadav path.area {
  fill: var(--mayawati--color);
}

.Ridgeline svg .serie.others path.area,
.Ridgeline svg .serie.banerjee path.area {
  fill: var(--others--color);
}

.Ridgeline svg .serie.kejriwal path.area {
  fill: var(--aap--color);
}

.Ridgeline svg .serie.nda path.line,
.Ridgeline svg .serie.yogi path.line,
.Ridgeline svg .serie.modi path.line {
  stroke: var(--nda--color);
}

.Ridgeline svg .serie.upa path.line,
.Ridgeline svg .serie.gandhi path.line {
  stroke: var(--upa--color);
}

.Ridgeline svg .serie.mayawati path.line {
  stroke: var(--mayawati--color);
}

.Ridgeline svg .serie.yadav path.line {
  stroke: var(--mayawati--color);
}

.Ridgeline svg .serie.others path.line,
.Ridgeline svg .serie.banerjee path.line {
  stroke: var(--others--color);
}

.Ridgeline svg .serie.kejriwal path.line {
  stroke: var(--aap--color);
}

.Ridgeline svg .label {
  fill: rgba(0, 0, 0, .75);
  font-size: 10px;
}

.Ridgeline svg .dateLine {
  /* mix-blend-mode: multiply; */
  stroke: rgba(0, 0, 0, .25);
  stroke-dasharray: 1 2;
  stroke-width: 1px;
}
