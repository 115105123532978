@mixin shadow() {
  text-shadow: var(--background--color) 1px 0 0,
    var(--background--color) 0.540302px 0.841471px 0,
    var(--background--color) -0.416147px 0.909297px 0,
    var(--background--color) -0.989992px 0.14112px 0,
    var(--background--color) -0.653644px -0.756802px 0,
    var(--background--color) 0.283662px -0.958924px 0,
    var(--background--color) 0.96017px -0.279415px 0;
}

.day-by-day-plot {
  position: relative;

  figure {
      margin: 0;
      padding: 30px 0 50px;
  }

  .day-by-day-plot--wrapper {
      height: 500px;
      position: relative;
      width: 100%;

      box-shadow: inset 0 4rem 4.2rem -4.2rem rgba(0,0,0,.1), inset 0 -4rem 4.2rem -4.2rem rgba(0,0,0,.1);
      border-bottom: 1px solid rgba(0,0,0,.1);
      border-top: 1px solid rgba(0,0,0,.1);

      canvas {
        position: relative;
        z-index: 9;
      }

      .annotation {
        pointer-events: none;
        z-index: 99999;

        p {
          @include shadow();
        }
      }

      .stories-arrow {
        position: absolute;
        display: block;
        text-align: center;
        line-height: 50px;
        width: 50px;
        height: 50px;
        border: 1px solid #ddd;
        font-size: 30px;
        font-weight: bold;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #fff;
        z-index: 99999;
        cursor: pointer;

        &:hover {
          background-color: var(--link--color);
          color: #fff;
        }
      }
  }

  .avg {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 0px;
    transform: translateY(-50%);
    background-color: transparent;
    border-top: 1px solid #666;

    .label {
      display: inline-block;
      font-size: 11px;
      color: #000;
      position: absolute;

      @include shadow();

      &.label-above {
        top: 5px;
      }
      &.label-below {
        transform: translateY(-100%);
        top: -5px;
      }

      @media screen and (min-width: 960px) {
        margin-left: 10px;
        color: #666;

        &.label-above {
          transform: translateY(-100%);
          top: -5px;
        }
        &.label-below {
          transform: translateY(100%);
          top: -5px;
        }
      }
    }
    &.percentile {
      border-top: 1px dashed #666;
    }
    &.p-range {
      border-top: none;
      background-color: var(--background--color);
      opacity: 0.75;
      height: unset;
      transform: none;
    }
  }

  ul.axis {
    &.x-axis {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      list-style: none;

      li {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        overflow: visible;

        &::before {
          content: '';
          border-left: 1px dotted rgba(68, 68, 68, 1);
          // border-style: none none dotted;
          background-color: transparent;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 1px;
          transform: translateX(-0.5px);
        }

        span {
          position: absolute;
          bottom: -5px;
          transform: translate(-50%, 100%);
          fill: rgba(68, 68, 68, 1);
          font-size: 10px;
          text-align: center;
          line-height: 12px;
          @media screen and (min-width: 960px) {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
