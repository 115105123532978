:root {
  --background--color: rgb(255, 252, 235);
  --background--color-alpha: rgb(255, 252, 235, 0.75);
  --text--color: rgb(68, 68, 68);
  --link--color: rgb(97, 84, 136);
  --highlight--color: rgb(229, 111, 135);
  --upa--color: rgb(47, 144, 177);
  --mayawati--color: rgb(102, 169, 49);
  --mahagathbandhan--color: rgb(102, 169, 49);
  --nda--color: rgb(255, 187, 12);
  --others--color: rgb(162, 117, 171);
  --aap--color: rgb(107, 233, 255);
  --shadow--color: rgba(68, 68, 68, 0.2);
}

html {
  font-size: 62.5%;
}

body {
  background: var(--background--color);
  border: 0;
  color: var(--text--color);
  font-family: 'Work Sans', sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--link--color);
  padding: 0 0.2rem;
}

a:active,
a:hover {
  background-color: var(--link--color);
  color: var(--background--color);
  text-decoration: none;
}

p {
  margin: 2rem 0 0 0;
}

p:first-child {
  margin-top: 0;
}

.India {
    display: block;
    height: auto;
    margin: 0 auto;
    padding: 0;
    position: relative;
    width: 100%;
}

.India .component-wrapper {
    display: block;
    margin: 4rem 2rem 0 2rem;
    position: relative;
    width: auto;
}

@media screen and (min-width: 960px) {
    .India .component-wrapper {
        margin: 6rem auto;
        max-width: 1280px;
        width: 85%;
    }
}

.India .highlight {
    color: var(--highlight--color);
}

.India .cols {
    columns: 1;
    margin-top: 4rem;
}

@media screen and (min-width: 760px) {
    .India .cols {
        columns: 1;
        margin: 2rem auto 2rem;
        width: 75%;
    }
}

@media screen and (min-width: 960px) {
    .India .cols {
        columns: 1;
        margin: 3rem auto 3rem;
        width: 50%;
    }
}

@media screen and (min-width: 1200px) {
    .India .cols {
        width: 40%;
    }
}

.India h1 {
    font-family: 'Spectral', Serif;
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 1.15;
    letter-spacing: 0px;
    margin: 2rem 0 0 0;
    padding: 0;
    text-align: center;
}

.India h2 {
    font-family: 'Spectral', Serif;
    font-size: 2.8rem;
    font-weight: 900;
    line-height: 4rem;
    letter-spacing: normal;
    margin: 2rem 0 0 0;
    padding: 0;
    text-align: center;
}

@media screen and (min-width: 960px) {
    .India h1 {
        font-size: 5rem;
        margin-top: 4rem;
    }
    .India h2 {
        font-size: 4.4rem;
        margin-top: 4rem;
    }
}

.India h3 {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    margin: 2rem 0 0 0;
    text-transform: uppercase;
}

.India p+h3 {
    margin-top: 4rem;
}

span.legend-nda {
    box-shadow: inset 0 -0.35rem 0 0 var(--nda--color);
    font-weight: 700;
    /* mix-blend-mode: multiply; */
}

span.legend-mahagathbandhan,
span.legend-mayawati {
    box-shadow: inset 0 -0.35rem 0 0 var(--mayawati--color);
    font-weight: 700;
    /* mix-blend-mode: multiply; */
}

span.legend-upa {
    box-shadow: inset 0 -0.35rem 0 0 var(--upa--color);
    font-weight: 700;
    /* mix-blend-mode: multiply; */
}

span.legend-others {
    box-shadow: inset 0 -0.35rem 0 0 var(--others--color);
    font-weight: 700;
    /* mix-blend-mode: multiply; */
}

.Header {
  background: var(--background--color);
  border-bottom: .1rem solid var(--text--color);
  display: block;
  height: 4rem;
  left: 0;
  margin: 0;
  overflow: visible;
  right: 0;
  top: 0;
  width: auto;
  z-index: 99;
}

@media screen and (min-width: 960px) {
  .Header {
    /* background: transparent; */
    border-bottom: none;
    height: 10rem;
  }

  .Header--wrapper {
    margin: 0 auto 0 auto;
    max-width: 160rem;
    width: 85%;
  }
}

.Header h1 {
  font-family: 'Spectral', Serif;
  font-size: 2rem;
  font-weight: 800;
  line-height: 4rem;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
  text-align: center;
}

.Header h1 .wheel {
  display: inline-block;
  height: 1.4rem;
  margin: 0 0 0 .1rem;
  vertical-align: baseline;
  width: 1.4rem;
}

.Header h1 .wheel img {
  display: inline-block;
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.Header h2 {
  display: none;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 3rem;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (min-width: 960px) {
  .Header h1 {
    font-size: 4.6rem;
    line-height: 4.8rem;
    margin-top: 1rem;
    text-align: center;
  }

  .Header h1 .wheel {
    height:3.2rem;
    margin-right: 0;
    /* mix-blend-mode: multiply; */
    width:3.2rem;
  }

  .Header h2 {
    display: block;
  }
}

.Footer {
    background: var(--text--color);
    color: var(--background--color);
    display: block;
    margin: 0;
    padding: 4rem 2rem;
}

.Footer .Footer--wrapper {
    display: block;
    margin: 0 2rem;
    max-width: 160rem;
    position: relative;
    width: auto;
}

.India .Footer--wrapper .col {
    margin-top: 2rem;
}

@media screen and (min-width: 960px) {
    .India .Footer {
        padding: 4rem 0;
    }
    .India .Footer--wrapper {
        align-content: stretch;
        align-items: stretch;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0 2rem;
        width: 100%;
    }
    .India .Footer--wrapper .col {
        display: block;
        flex: 0 0 30%;
        margin-top: 0;
        max-width: 30%;
    }
    .India .Footer--wrapper .col:last-child {
        flex: 0 0 60%;
        max-width: 60%;
    }
}

.Footer p {
    font-size: 1.2rem;
    margin-top: 2rem;
}

.Footer p:first-child {
    margin-top: 0;
}

.Footer h3 {
    font-size: 1.6rem;
}

.Footer h3:first-child,
.Footer h3+p {
    margin-top: 0;
}

.Footer h3 a {
    color: var(--background--color);
    text-decoration: none;
}

.Footer h3 a:hover,
.Footer h3 a:active {
    background: transparent;
    color: var(--background--color);
    text-decoration: none;
}

.Footer img {
    height: 3rem;
    object-fit: contain;
    vertical-align: middle;
    width: 3rem;
}

.Footer a {
    color: var(--background--color);
    text-decoration: underline;
}

.Footer a:hover,
.Footer a:active {
    background: transparent;
}


.Challenge {
    display: block;
    min-height: 90vh;
    padding-top: 4rem;
    position: relative;
    width: 100%;
}

@media screen and (min-width: 960px) {
    .Challenge {
        padding-top: 8rem;
    }
}

.Challenge .in {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
}

.Challenge .masks,
.Challenge .portraits {
    background: var(--background--color);
    display: block;
    height: 40vh;
    margin: 0 auto;
    max-width: 160rem;
    position: relative;
    top: 4rem;
    z-index: 59;
}

.Challenge .masks {
    background: transparent;
    display: none;
}

.Challenge .masks .modi {
  margin-top: -1%;
}

.Challenge .masks .gandhi {
  margin-top: -1.5%;
}

@media screen and (min-width: 960px) {
    .Challenge .masks,
    .Challenge .portraits {
        background: transparent;
        display: block;
        position: -webkit-sticky;
        position: sticky;
        top: 18rem;
    }
}

.Challenge .image {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 60vw;
    z-index: 1;
}

@media screen and (min-width: 960px) {
    .Challenge .image {
        width: 25%;
    }
}

@media screen and (min-width: 1200px) {
    .Challenge .image {
        width: 30%;
    }
}

.Challenge .image.gandhi {
    left: auto;
    right: 0;
}

.Challenge figure {
    display: block;
    height: 100%;
    margin: 0;
    width: auto;
}

.Challenge figure img {
    display: block;
    height: 100%;
    /* mix-blend-mode: multiply; */
    object-fit: contain;
    width: 100%;
}

.Challenge .image.modi .face {
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translate(-50%, 0)
}

.Challenge .image.gandhi .face {
    position: absolute;
    top: -6%;
    left: 54%;
    transform: translate(-50%, 0)
}

.Challenge .texts {
    margin-top: 6rem;
}

@media screen and (min-width: 960px) {
    .Challenge .texts {
        margin-top: -30vh;
    }
    .Challenge .texts.intro-text {
        margin-top: 0;
    }
    .Challenge .image.modi .face {
        top: 2%;
        left: 50%;
    }
    .Challenge .image.gandhi .face {
        top: -4%;
        left: 57%;
    }
}

.Challenge .intro p {
    font-family: 'Spectral', Serif;
    font-size: 2.2rem;
    /* font-style: italic; */
    font-weight: 800;
    /* text-align: center; */
    line-height: 3rem;
}

.Challenge .intro.stand-first p {
    font-family: 'Spectral', Serif;
    font-size: 2.2rem;
    font-weight: 200;
    /* text-align: center; */
    line-height: 3rem;
}

.Challenge .intro.stand-first p span[role="img"]{
  font-style: normal;
}

.Challenge .chart--intro h3 {
    text-align: center;
}

.Challenge .chart-wrapper {
    display: block;
    height: 4rem;
    margin: 2rem auto;
    position: relative;
    width: 90%;
}

@media screen and (min-width: 960px) {
    .Challenge .chart-wrapper {
        margin: 3rem auto;
        width: 40%;
    }
}

.Challenge .chart-wrapper:last-child {
    margin-bottom: 0;
}

@media screen and (min-width: 960px) {
    .Challenge h1 {
        padding: 6rem 0;
    }
}

.Challenge #head2Head {
    position: -webkit-sticky;
    position: sticky;
    top: calc(-100vh + 18rem);
    z-index: 999;
}

.Challenge #head2Head .face {
    position: absolute;
    top: 100vh;
}

.Challenge #head2Head .face.modi {
    left: 0;
    transform: translate(-50%, 0)
}

.Challenge #head2Head .face.rahul {
    right: 0;
    transform: translate(-50%, 0)
}

.LollipopChart {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    margin: 0 auto;
}

.LollipopChart .right-wrapper,
.LollipopChart .left-wrapper {
    bottom: auto;
    height: 4rem;
    left: 0;
    position: absolute;
    right: 50%;
    top: 0;
    width: auto;
    transition: transform 1s;
}

.LollipopChart .left-wrapper {
    transform-origin: 100% 50%;
}

.LollipopChart .right-wrapper {
    left: 50%;
    right: 0;
    transform-origin: 0 50%;
}

.LollipopChart.vertical .left-wrapper {
    transform: rotate(-90deg);
}

.LollipopChart.vertical .right-wrapper {
    transform: rotate(90deg);
}

.LollipopChart .right-wrapper .in:after,
.LollipopChart .left-wrapper .in:after {
    background: var(--shadow--color);
    content: '';
    display: none;
    height: 3rem;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: .1rem;
}

.LollipopChart .left-wrapper .in:after {
    right: 0;
    left: auto;
}

.LollipopChart .in {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
}

.LollipopChart .in.w-separator:after {
    background: rgba(0, 0, 0, .15);
    bottom: .7rem;
    content: '';
    display: block;
    height: auto;
    /* mix-blend-mode: multiply; */ /* it breaks the faces */
    position: absolute;
    right: -.1rem;
    top: .7rem;
    transform: translate3d(0, 0, 0);
    width: .2rem;
}

.LollipopChart .line {
    /* background: var(--text--color); */
    background: transparent;
    border-bottom: .2rem dotted rgba(0, 0, 0, .15);
    display: block;
    /* height: .4rem; */
    height: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: .1rem;
}

.LollipopChart .bullet {
    background: var(--text--color);
    border: .4rem solid var(--text--color);
    border-radius: .2rem;
    display: block;
    height: 1.8rem;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0) rotate(45deg);
    width: 1.8rem;
}

.LollipopChart .legend {
    bottom: 0;
    height: 2rem;
    left: 0;
    line-height: 2rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: 4rem;
    width: auto;
}

.LollipopChart .legend .item {
    font-size: 1.2rem;
    margin: 0 1.5rem;
    padding-left: 1.6rem;
    position: relative;
    text-transform: uppercase;
}

.LollipopChart .legend .item:before {
    content: '';
    border-radius: 50%;
    display: block;
    height: 1rem;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 1rem;
}

.LollipopChart .legend .item.nda:before,
.LollipopChart .legend .item.modi:before {
    background-color: var(--nda--color);
}

.LollipopChart .legend .item.upa:before,
.LollipopChart .legend .item.gandhi:before {
    background-color: var(--upa--color);
}

.LollipopChart .nda .bullet,
.LollipopChart .modi .bullet {
    background-color: var(--nda--color);
    border-color: var(--nda--color);
}

.LollipopChart .upa .bullet,
.LollipopChart .gandhi .bullet {
    background-color: var(--upa--color);
    border-color: var(--upa--color);
}

.LollipopChart .nda .line,
.LollipopChart .modi .line {
    border-color: var(--nda--color);
}

.LollipopChart .upa .line,
.LollipopChart .gandhi .line {
    border-color: var(--upa--color);
}

.LollipopChart .label {
    display: inline-block;
    font-size: 1.1rem;
    line-height: 1;
    padding: 0 3rem;
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: auto;
    white-space: nowrap;
}
.LollipopChart .label b {
  font-size: 2rem;
  font-weight: 200;
  display: block;
  text-align: center;
}

.LollipopChart .right-wrapper .label {
    padding: 0 0 0 3rem;
    text-align: left;
}

.LollipopChart .left-wrapper .label {
    padding: 0 3rem 0 0;
    text-align: right;
}

.LollipopChart.vertical .right-wrapper .label {
    transform-origin: 0 0%;
    transform: rotate(-90deg);
}

.LollipopChart.vertical .left-wrapper .label {
    transform-origin: 100% 0%;
    transform: rotate(90deg);
}

.annotation {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  overflow: visible;
  width: 15px;
  height: 15px;
  transform: translate(-100%, -100%); }
  @media (min-width: 768px) {
    .annotation {
      width: 25px;
      height: 25px;
      z-index: 9; } }
  .annotation p.text {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    padding-bottom: 3px;
    width: 120px;
    transform: translate(-100%, -100%);
    text-align: right; }
    .annotation p.text.centered {
      transform: translate(-50%, -100%); }
    .annotation p.text b {
      display: inline-block;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: #000;
      color: #fff;
      padding: 1px;
      text-align: center;
      line-height: 9px;
      font-weight: 900; }
  .annotation .arrow {
    width: inherit;
    height: inherit;
    position: relative;
    overflow: hidden; }
    .annotation .arrow span {
      position: absolute;
      border: 1px solid #888;
      border-radius: 50%;
      width: 200%;
      height: 200%;
      left: 0;
      bottom: 0; }
  .annotation.straight {
    transform: translate(-100%, 0); }
    .annotation.straight p.text {
      transform: translate(-100%, -50%);
      padding-right: 5px; }
    .annotation.straight .arrow {
      overflow: visible; }
      .annotation.straight .arrow span {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        border-top: 1px solid #888; }
  .annotation.deg45 .arrow {
    overflow: visible;
    transform: translate(10%, 30%) rotate(-45deg);
    transform-origin: 50%;
    width: 140%; }
    .annotation.deg45 .arrow span {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
      border-top: 1px solid #888; }
  .annotation.no-arrow {
    transform: translate(-50%, 0%); }
    .annotation.no-arrow p.text {
      left: 50%;
      transform: translate(-50%, -70%);
      padding-right: 0;
      padding-left: 0;
      text-align: left; }
      .annotation.no-arrow p.text h4 {
        font-size: inherit;
        font-family: inherit;
        margin: 0;
        padding: 0; }
    .annotation.no-arrow.right p.text {
      transform: translate(0%, -100%); }
    .annotation.no-arrow .arrow {
      display: none; }
  .annotation.left-to-right.long.straight p.text {
    left: -200%; }
  .annotation.left-to-right.long.straight .arrow span {
    width: 300%;
    left: -200%; }
  .annotation.right-to-left {
    transform: translate(0, -100%); }
    .annotation.right-to-left p.text {
      transform: translate(0, -100%);
      text-align: left;
      left: 100%; }
      .annotation.right-to-left p.text.centered {
        text-align: center;
        transform: translate(-50%, -100%); }
    .annotation.right-to-left .arrow span {
      left: auto;
      right: 0; }
    .annotation.right-to-left.straight {
      transform: translate(0, 0); }
      .annotation.right-to-left.straight p.text {
        transform: translate(0, -50%);
        padding-left: 5px; }
    .annotation.right-to-left.45deg {
      transform: translate(0, 0); }
  .annotation.top-to-bottom {
    transform: translate(-50%, -100%); }
  .annotation.bottom-to-top {
    transform: translate(-50%, -100%); }
    .annotation.bottom-to-top p.text {
      transform: translate(-50%, -100%);
      text-align: center;
      left: 50%; }
    .annotation.bottom-to-top .arrow span {
      left: -1px;
      transform: translate(50%, 0);
      width: 0;
      height: 100%;
      border-top: none;
      border-left: 1px solid #888; }
    .annotation.bottom-to-top.straight {
      transform: translate(0, 0); }
      .annotation.bottom-to-top.straight p.text {
        transform: translate(-50%, 0);
        padding: 0;
        padding-top: 5px;
        left: 0;
        top: 100%; }
      .annotation.bottom-to-top.straight.left-aligned p.text {
        transform: translate(-10%, 0);
        text-align: left; }
    .annotation.bottom-to-top.long.straight p.text {
      top: 200%; }
    .annotation.bottom-to-top.long.straight .arrow span {
      height: 200%;
      top: 0; }
    .annotation.bottom-to-top.very-long.straight p.text {
      top: 300%; }
    .annotation.bottom-to-top.very-long.straight .arrow span {
      height: 300%;
      top: 0; }

.tile-map {
  position: relative;
  width: calc(100% - 2rem);
  margin-left: 2rem;
  flex-direction: column; }
  .tile-map .row {
    width: 100%;
    height: calc(100% / 9);
    display: flex;
    flex-basis: calc(100% / 9); }
    .tile-map .row .cell {
      width: calc(100% / 9);
      height: 100%;
      position: relative; }
      .tile-map .row .cell:after {
        content: "";
        display: block;
        padding-bottom: 100%; }
  @media screen and (min-width: 568px) {
    .tile-map {
      width: 100%;
      margin-left: 0;
      padding: 0; } }
  .tile-map .tile-key {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px; }
    .tile-map .tile-key:before {
      content: 'Key';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      font-size: 11px;
      transform: translateY(-100%); }
    .tile-map .tile-key ul.keys {
      position: absolute;
      top: 0;
      left: 0px;
      transform: translateX(-100%);
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none; }
      .tile-map .tile-key ul.keys li {
        font-size: 10px;
        text-align: right;
        height: 25%;
        line-height: 12.5px;
        padding-right: 0.2rem; }
  .tile-map .tile {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 2px solid transparent;
    outline-color: transparent;
    outline-style: none;
    cursor: pointer; }
    .tile-map .tile[rel='delhi'] {
      border: 2px solid #000; }
    .tile-map .tile:hover {
      border-color: #000;
      border-top-color: var(--background--color);
      z-index: 9999; }
      .tile-map .tile:hover .state-name {
        display: inline-block; }
    .tile-map .tile .state-name {
      position: absolute;
      display: none;
      font-size: 12px;
      line-height: 16px;
      background-color: var(--background--color);
      transform: translate(-2px, -100%);
      top: 2px;
      text-transform: capitalize;
      pointer-events: none;
      white-space: nowrap;
      border: 2px solid #000;
      min-width: calc(100% + 4px);
      z-index: 9;
      padding: 3px; }
    .tile-map .tile .chart-container {
      background-color: #f9f2ca;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 90; }
    .tile-map .tile ul.slices {
      list-style: none;
      margin: 0;
      position: relative;
      height: 100%;
      padding: 0; }
      .tile-map .tile ul.slices li {
        position: absolute;
        width: 100%; }
        .tile-map .tile ul.slices li.yogi, .tile-map .tile ul.slices li.modi, .tile-map .tile ul.slices li.nda {
          background-color: var(--nda--color); }
        .tile-map .tile ul.slices li.gandhi, .tile-map .tile ul.slices li.upa {
          background-color: var(--upa--color); }
        .tile-map .tile ul.slices li.mayawati, .tile-map .tile ul.slices li.yadav, .tile-map .tile ul.slices li.mahagathbandhan {
          background-color: var(--mayawati--color); }
        .tile-map .tile ul.slices li.banerjee, .tile-map .tile ul.slices li.others {
          background-color: var(--others--color); }
        .tile-map .tile ul.slices li.kejriwal {
          background-color: var(--aap--color); }
  .tile-map .annotation p.text {
    width: auto;
    min-width: 70px; }

.Ridgeline {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.Ridgeline svg .line {
  fill: none;
  /* mix-blend-mode: multiply; */
  opacity: .2;
  stroke: rgb(68, 68, 68);
  stroke-dasharray: none;
  stroke-width: 1px;
}

.Ridgeline svg .serie path.area {
  fill: red;
  /* mix-blend-mode: multiply; */
  opacity: .25;
  stroke: none;
  stroke-dasharray: none;
  stroke-width: 0;
}

.Ridgeline svg .serie path.line {
  fill: none;
  opacity: .85;
  stroke: red;
  stroke-dasharray: none;
  stroke-width: .1rem;
}

.Ridgeline svg .serie text {
  font-size: 1rem;
  text-transform: uppercase;
}

.Ridgeline svg .serie line {
  stroke: rgba(0, 0, 0, .25);
  stroke-width: .1rem;
}

.Ridgeline svg .serie.nda path.area,
.Ridgeline svg .serie.yogi path.area,
.Ridgeline svg .serie.modi path.area {
  fill: var(--nda--color);
}

.Ridgeline svg .serie.upa path.area,
.Ridgeline svg .serie.gandhi path.area {
  fill: var(--upa--color);
}

.Ridgeline svg .serie.mayawati path.area {
  fill: var(--mayawati--color);
}

.Ridgeline svg .serie.yadav path.area {
  fill: var(--mayawati--color);
}

.Ridgeline svg .serie.others path.area,
.Ridgeline svg .serie.banerjee path.area {
  fill: var(--others--color);
}

.Ridgeline svg .serie.kejriwal path.area {
  fill: var(--aap--color);
}

.Ridgeline svg .serie.nda path.line,
.Ridgeline svg .serie.yogi path.line,
.Ridgeline svg .serie.modi path.line {
  stroke: var(--nda--color);
}

.Ridgeline svg .serie.upa path.line,
.Ridgeline svg .serie.gandhi path.line {
  stroke: var(--upa--color);
}

.Ridgeline svg .serie.mayawati path.line {
  stroke: var(--mayawati--color);
}

.Ridgeline svg .serie.yadav path.line {
  stroke: var(--mayawati--color);
}

.Ridgeline svg .serie.others path.line,
.Ridgeline svg .serie.banerjee path.line {
  stroke: var(--others--color);
}

.Ridgeline svg .serie.kejriwal path.line {
  stroke: var(--aap--color);
}

.Ridgeline svg .label {
  fill: rgba(0, 0, 0, .75);
  font-size: 10px;
}

.Ridgeline svg .dateLine {
  /* mix-blend-mode: multiply; */
  stroke: rgba(0, 0, 0, .25);
  stroke-dasharray: 1 2;
  stroke-width: 1px;
}

.interactive-map {
  padding: 0;
  width: 100%;
  margin-left: 0; }
  @media screen and (min-width: 568px) {
    .interactive-map {
      display: flex;
      width: calc(100% - 8rem);
      margin-left: 4rem; }
      .interactive-map > div {
        flex: 1 1 50%; } }
  .interactive-map .state-description h2 {
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 2rem;
    line-height: 2.2rem;
    display: none; }
  .interactive-map .state-description h3 {
    text-align: left;
    margin: 0;
    margin-top: 2rem; }
  .interactive-map .state-description ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0; }
  @media screen and (min-width: 568px) {
    .interactive-map .state-description {
      padding-left: 3rem; }
      .interactive-map .state-description h2 {
        margin-bottom: 2rem;
        font-size: 3rem;
        line-height: 3.2rem;
        display: inline-block; }
      .interactive-map .state-description ul {
        display: block; } }
  .interactive-map .note {
    margin-top: 2rem;
    font-size: 11px;
    display: inline-block;
    color: #333; }
    .interactive-map .note.large {
      display: none; }
    @media screen and (min-width: 568px) {
      .interactive-map .note.large {
        display: inline-block; }
      .interactive-map .note.small {
        display: none; } }

.States .States--charts {
    display: block;
    margin: 0 auto;
    max-width: 160rem;
    width: 100%;
}

@media screen and (min-width: 960px) {
    .States .States--charts {
        display: flex;
        flex-wrap: wrap;
    }
}

.States .States--state {
    display: none;
    padding: 1rem 0;
    position: relative;
}

.States .States--state.Maharashtra,
.States .States--state.Karnataka,
.States .States--state.JammuandKashmir,
.States .States--state.UttarPradesh,
.States .States--state.WestBengal,
.States .States--state.Delhi {
  display: block;
}

.States--charts.show-all .States--state {
  display: block;
}

@media screen and (min-width: 960px) {
    .States .States--state {
        flex: 0 0 33.33%;
        padding: 1rem 2rem 2rem 2rem;
    }
}

.States .States--chart {
    box-shadow: inset 0 4rem 4.2rem -4.2rem rgba(0, 0, 0, .1), inset 0 -4rem 4.2rem -4.2rem rgba(0, 0, 0, .1);
    border: 1px solid rgba(0, 0, 0, .1);
    height: 20rem;
    margin: 1rem 0 0 0;
    padding: 0 0 .5rem 0;
    position: relative;
    width: 100%;
}

.States h3 {
    font-size: 1.4rem;
    margin-top: 0;
    text-align: center;
}

.States .States--show-more {
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin: 100px auto 100px auto;
  outline: none;
  position: relative;
  width: 141px;
  height: 100px;
}

.States .States--show-more span {
  display: block;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.States .States--show-more:before {
  background-color: #eee;
  border: 2px solid #000;
  content: '';
  display: inline-block;
  height: 80px;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: -50%, -50%;
  transition-duration: 0.8s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 80px;
}

.States .States--show-more:hover:before {
  background-color: var(--link--color);
  border: 3px solid #000;
}

.States .States--show-more:hover span {
  color:#fff;
}

    .StreamGraph {
        bottom: 0;
        display: block;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }

    .StreamGraph svg .line {
        /* mix-blend-mode: multiply; */
        stroke: rgba(68, 68, 68, 0.6);
        stroke-dasharray: 1 2;
        stroke-width: 1px;
    }

    .StreamGraph svg .bullet {
        /* mix-blend-mode: multiply; */
        stroke: rgba(68, 68, 68, 0.6);
        stroke-width: 1px;
    }

    .StreamGraph svg .label {
        fill: rgba(68, 68, 68, 1);
        font-size: 10px;
    }

    .StreamGraph svg .legend {
        fill: rgba(68, 68, 68, 1);
        font-size: 10px;
        text-transform: uppercase;
    }

    .StreamGraph svg .area.upa {
        fill: var(--upa--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .area.mayawati {
        fill: var(--mayawati--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .area.nda {
        fill: var(--nda--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .area.others {
        fill: var(--others--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .circle.upa {
        fill: var(--upa--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .circle.mayawati {
        fill: var(--mayawati--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .circle.nda {
        fill: var(--nda--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .circle.others {
        fill: var(--others--color);
        /* mix-blend-mode: multiply; */
    }

    .StreamGraph svg .dynamic-labels text {
        fill: rgb(68, 68, 68);
        font-size: 14px;
        paint-order: stroke;
        stroke: var(--background--color);
        stroke-width: 2px;
        stroke-linecap: round;
        stroke-linejoin: round;
        font-weight: 400;
        text-transform: uppercase;
        text-anchor: middle;
    }

    .StreamGraph .legend {
        left: 1rem;
        position: absolute;
        top: 1rem;
        z-index: 2;
    }

    @media screen and (min-width: 960px) {
        .StreamGraph .legend {
            bottom: 1rem;
            left: 50%;
            top: auto;
            transform: translate3d(-50%, 0, 0);
        }
    }

    .StreamGraph .legend ul,
    .StreamGraph .legend li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .StreamGraph .legend li {
        font-size: 1rem;
        height: 2rem;
        line-height: 2rem;
        position: relative;
        text-transform: uppercase;
    }

    @media screen and (min-width: 960px) {
        .StreamGraph .legend li {
            display: inline-block;
            padding: 0 1rem;
        }
    }

    .StreamGraph .legend .circle {
        display: block;
        height: 2rem;
        line-height: 2rem;
        padding-left: 2rem;
        position: relative;
    }

    .StreamGraph .legend .circle:before {
        background: #eee;
        border-radius: 50%;
        content: '';
        display: block;
        height: 1.2rem;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        width: 1.2rem;
    }

    .StreamGraph .legend .circle.upa:before {
        background: var(--upa--color);
    }

    .StreamGraph .legend .circle.nda:before {
        background: var(--nda--color);
    }

    .StreamGraph .legend .circle.mayawati:before {
        background: var(--mayawati--color);
    }

    .StreamGraph .legend .circle.others:before {
        background: var(--others--color);
    }

.OverviewStreamGraph {
  margin-top: 8rem;
}

@media screen and (min-width: 960px) {
  .OverviewStreamGraph {
    margin-top: 15rem;
  }
}

.OverviewStreamGraph .chart--wrapper {
  background-color: var(--background--color);
}

@media screen and (min-width: 960px) {
  .OverviewStreamGraph .chart--wrapper {
    position: relative;
    top: 0;
    z-index: 2;
  }
}

.OverviewStreamGraph figure {
  margin: 0;
  padding-top: 1rem;
}

@media screen and (min-width: 960px) {
  .OverviewStreamGraph figure {
    padding-top: 1rem;
  }
}

.OverviewStreamGraph .figcaption {
  position: relative;
  text-align: center;
}

.OverviewStreamGraph .OverviewStreamGraph--wrapper {
  box-shadow: inset 0 4rem 4.2rem -4.2rem rgba(0, 0, 0, 0.1),
    inset 0 -4rem 4.2rem -4.2rem rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 25rem;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 960px) {
  .OverviewStreamGraph .OverviewStreamGraph--wrapper {
    height: 30rem;
  }
}

@media screen and (min-width: 1200px) {
  .OverviewStreamGraph .OverviewStreamGraph--wrapper {
    height: 35rem;
  }
}

.LineChart {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.LineChart svg .line {
  fill: none;
  /* mix-blend-mode: multiply; */
  opacity: .2;
  stroke: rgb(68, 68, 68);
  stroke-dasharray: none;
  stroke-width: 1px;
}

.LineChart svg .line.selected {
  fill: none;
  opacity: 1;
  stroke: var(--highlight--color);
  stroke-dasharray: none;
  stroke-width: 2px;
}

.LineChart svg .xAxis {
  /* mix-blend-mode: multiply; */
  stroke: rgba(0, 0, 0, .1);
  stroke-dasharray: none;
  stroke-width: 1px;
}

.LineChart svg .dateLine {
  /* mix-blend-mode: multiply; */
  stroke: rgba(0, 0, 0, .25);
  stroke-dasharray: 1 2;
  stroke-width: 1px;
}

.LineChart svg .label {
  fill: rgba(0, 0, 0, .75);
  font-size: 10px;
}

.LineChart svg .bullet {
  /* mix-blend-mode: multiply; */
  fill: rgba(0, 0, 0, .25);
}

.LineChart .annotation {
  background-color: rgb(255, 252, 235, 0.5);
  border-radius: 2rem;
  display: none;
  height: auto;
  min-width: 20rem;
  opacity: 0;
  padding: 0;
  position: absolute;
  transform: translate(0,0);
  transition: opacity .5s ease-in-out;
  width: auto;
  z-index: 9;
}

.LineChart .visible.annotation {
  display: block;
}

.LineChart .visible.animate.annotation {
  opacity: 1;
}

.LineChart .annotation article {
  display: block;
  height: auto;
  position: relative;
  width: auto;
}

.LineChart .annotation.top article {
  margin-bottom: -.75rem;
}

.LineChart .annotation.bottom article {
  margin-top: -.75rem;
}

.LineChart .annotation.right.bottom {
  padding-right: 4rem;
  transform: translate(-100%,0);
}

.LineChart .annotation.right.top {
  padding-right: 4rem;
  transform: translate(-100%,-100%);
}

.LineChart .annotation.left.bottom {
  padding-left: 4rem;
  transform: translate(0,0);
}

.LineChart .annotation.left.top {
  padding-left: 4rem;
  transform: translate(0,-100%);
}

.LineChart .annotation.right.bottom:after {
  background-color: var(--text--color);
  content: '';
  display: block;
  height: .1rem;
  position: absolute;
  right:  1rem;
  top: -.1rem;
  transform-origin: center center;
  width: 2.5rem;
}

.LineChart .annotation.right.top:after {
  background-color: var(--text--color);
  bottom: -.1rem;
  content: '';
  display: block;
  height: .1rem;
  position: absolute;
  right:  1rem;
  transform-origin: center center;
  width: 2.5rem;
}

.LineChart .annotation.left.bottom:after {
  background-color: var(--text--color);
  content: '';
  display: block;
  height: .1rem;
  position: absolute;
  left:  1rem;
  top: 0;
  transform-origin: center center;
  width: 2.5rem;
}

.LineChart .annotation.left.top:after {
  background-color: var(--text--color);
  bottom: -.1rem;
  content: '';
  display: block;
  height: .1rem;
  position: absolute;
  left:  1rem;
  transform-origin: center center;
  width: 2.5rem;
}

.LineChart .annotation a,
.LineChart .annotation a:hover,
.LineChart .annotation a:active,
.LineChart .annotation a h3,
.LineChart .annotation a h2 {
  background-color: transparent;
  box-shadow: none;
  color: var(--text--color);
  font-family: 'Work Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-shadow: var(--background--color) 1px 0 0,
    var(--background--color) 0.540302px 0.841471px 0,
    var(--background--color) -0.416147px 0.909297px 0,
    var(--background--color) -0.989992px 0.14112px 0,
    var(--background--color) -0.653644px -0.756802px 0,
    var(--background--color) 0.283662px -0.958924px 0,
    var(--background--color) 0.96017px -0.279415px 0;
}



.LineChart .annotation.right a,
.LineChart .annotation.right a:hover,
.LineChart .annotation.right a:active,
.LineChart .annotation.right a h3,
.LineChart .annotation.right a h2 {
  text-align: right;
}

@keyframes lens {
  from {
    box-shadow: 0 0 0 2px rgba(229, 111, 135, 0), 0 0 0 5px rgba(229, 111, 135, 0), 0 0 0 9px rgba(229, 111, 135, 0);
  }
  to {
    box-shadow: 0 0 0 2px rgba(229, 111, 135, .4), 0 0 0 5px rgba(229, 111, 135, .2), 0 0 0 9px rgba(229, 111, 135, .1);
  }
}


.LineChart .lens {
  background: transparent;
  border: .1rem solid var(--text--color);
  border-radius: .2rem;
  cursor: pointer;
  display: block;
  height: 1rem;
  position: absolute;
  transform-origin: center center;
  transform: translate3d(-50%,-50%,0) rotate(45deg);
  width: 1rem;
}

.LineChart .lens.animate {
  animation-name: lens;
  animation-duration: 4.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-fill-mode:  both;
  animation-play-state: running;
}

.CompareCandidates .CompareCandidates--charts {
    display: block;
    margin: 0 auto;
    max-width: 160rem;
    width: 100%;
}

@media screen and (min-width: 960px) {
    .CompareCandidates .CompareCandidates--charts {
        display: flex;
        flex-wrap: wrap;
    }
}

.CompareCandidates .CompareCandidates--candidate {
    display: block;
    padding: 1rem 0;
    position: relative;
}

@media screen and (min-width: 960px) {
    .CompareCandidates .CompareCandidates--candidate {
        flex: 0 0 33%;
        padding: 1rem 2rem 2rem 2rem;
    }
}

.CompareCandidates .CompareCandidates--chart {
    box-shadow: inset 0 4rem 4.2rem -4.2rem rgba(0, 0, 0, .1), inset 0 -4rem 4.2rem -4.2rem rgba(0, 0, 0, .1);
    border: 1px solid rgba(0, 0, 0, .1);
    height: 17.5rem;
    margin: 1rem 0 0 0;
    padding: 0 0 .5rem 0;
    position: relative;
    width: 100%;
}

.CompareCandidates h3 {
    font-size: 1.4rem;
    margin-top: 0;
    text-align: center;
}
.top-news {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; }
  .top-news .top-news-container > div {
    position: absolute;
    transform: translateX(-50%);
    height: 100%; }
    .top-news .top-news-container > div:hover {
      background-color: rgba(0, 0, 0, 0); }
      .top-news .top-news-container > div:hover article,
      .top-news .top-news-container > div:hover span.top-news-arrow {
        display: block; }
    .top-news .top-news-container > div article {
      position: absolute;
      top: -10px;
      transform: translate(-50%, -100%);
      font-size: 1.1rem;
      line-height: 1.2rem;
      width: 200px;
      position: absolute;
      text-align: center;
      display: none; }
    .top-news .top-news-container > div span.top-news-arrow {
      position: absolute;
      width: 1px;
      left: 50%;
      background-color: #666;
      transform: translateX(-50%);
      display: none; }
  .top-news .story-news {
    width: 100%;
    position: absolute; }
    .top-news .story-news .story-news-container {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: flex-end;
      padding-right: 80px; }
      .top-news .story-news .story-news-container article {
        width: calc(100% - 75px);
        display: none;
        text-shadow: var(--background--color) 1px 0 0, var(--background--color) 0.5403px 0.84147px 0, var(--background--color) -0.41615px 0.9093px 0, var(--background--color) -0.98999px 0.14112px 0, var(--background--color) -0.65364px -0.7568px 0, var(--background--color) 0.28366px -0.95892px 0, var(--background--color) 0.96017px -0.27942px 0;
        text-align: left; }
        .top-news .story-news .story-news-container article:first-child {
          display: block; }
        @media screen and (min-width: 768px) {
          .top-news .story-news .story-news-container article {
            display: block;
            width: 40%;
            padding-top: 0;
            padding-bottom: 0;
            position: relative;
            left: unset;
            right: 75px;
            padding-right: 2rem; } }
        .top-news .story-news .story-news-container article h4 {
          font-size: 1.1rem;
          text-transform: uppercase;
          font-weight: 300;
          margin: 0;
          margin-top: 0.2rem; }
        .top-news .story-news .story-news-container article h5 {
          font-size: 1.1rem;
          text-transform: uppercase;
          font-weight: 300;
          margin: 0;
          margin-top: 0.2rem; }
        .top-news .story-news .story-news-container article h2 {
          font-size: 1.6rem;
          line-height: 1.8rem;
          text-align: left;
          margin-top: 0.5rem; }
          @media screen and (min-width: 960px) {
            .top-news .story-news .story-news-container article h2 {
              font-size: 2.2rem;
              line-height: 2.4rem; } }
        .top-news .story-news .story-news-container article p {
          font-size: 1.3rem;
          margin-top: 0.2rem; }

.day-by-day-plot {
  position: relative; }
  .day-by-day-plot figure {
    margin: 0;
    padding: 30px 0 50px; }
  .day-by-day-plot .day-by-day-plot--wrapper {
    height: 500px;
    position: relative;
    width: 100%;
    box-shadow: inset 0 4rem 4.2rem -4.2rem rgba(0, 0, 0, 0.1), inset 0 -4rem 4.2rem -4.2rem rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .day-by-day-plot .day-by-day-plot--wrapper canvas {
      position: relative;
      z-index: 9; }
    .day-by-day-plot .day-by-day-plot--wrapper .annotation {
      pointer-events: none;
      z-index: 99999; }
      .day-by-day-plot .day-by-day-plot--wrapper .annotation p {
        text-shadow: var(--background--color) 1px 0 0, var(--background--color) 0.5403px 0.84147px 0, var(--background--color) -0.41615px 0.9093px 0, var(--background--color) -0.98999px 0.14112px 0, var(--background--color) -0.65364px -0.7568px 0, var(--background--color) 0.28366px -0.95892px 0, var(--background--color) 0.96017px -0.27942px 0; }
    .day-by-day-plot .day-by-day-plot--wrapper .stories-arrow {
      position: absolute;
      display: block;
      text-align: center;
      line-height: 50px;
      width: 50px;
      height: 50px;
      border: 1px solid #ddd;
      font-size: 30px;
      font-weight: bold;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #fff;
      z-index: 99999;
      cursor: pointer; }
      .day-by-day-plot .day-by-day-plot--wrapper .stories-arrow:hover {
        background-color: var(--link--color);
        color: #fff; }
  .day-by-day-plot .avg {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 0px;
    transform: translateY(-50%);
    background-color: transparent;
    border-top: 1px solid #666; }
    .day-by-day-plot .avg .label {
      display: inline-block;
      font-size: 11px;
      color: #000;
      position: absolute;
      text-shadow: var(--background--color) 1px 0 0, var(--background--color) 0.5403px 0.84147px 0, var(--background--color) -0.41615px 0.9093px 0, var(--background--color) -0.98999px 0.14112px 0, var(--background--color) -0.65364px -0.7568px 0, var(--background--color) 0.28366px -0.95892px 0, var(--background--color) 0.96017px -0.27942px 0; }
      .day-by-day-plot .avg .label.label-above {
        top: 5px; }
      .day-by-day-plot .avg .label.label-below {
        transform: translateY(-100%);
        top: -5px; }
      @media screen and (min-width: 960px) {
        .day-by-day-plot .avg .label {
          margin-left: 10px;
          color: #666; }
          .day-by-day-plot .avg .label.label-above {
            transform: translateY(-100%);
            top: -5px; }
          .day-by-day-plot .avg .label.label-below {
            transform: translateY(100%);
            top: -5px; } }
    .day-by-day-plot .avg.percentile {
      border-top: 1px dashed #666; }
    .day-by-day-plot .avg.p-range {
      border-top: none;
      background-color: var(--background--color);
      opacity: 0.75;
      height: unset;
      transform: none; }
  .day-by-day-plot ul.axis.x-axis {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    list-style: none; }
    .day-by-day-plot ul.axis.x-axis li {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      overflow: visible; }
      .day-by-day-plot ul.axis.x-axis li::before {
        content: '';
        border-left: 1px dotted #444444;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 1px;
        transform: translateX(-0.5px); }
      .day-by-day-plot ul.axis.x-axis li span {
        position: absolute;
        bottom: -5px;
        transform: translate(-50%, 100%);
        fill: #444444;
        font-size: 10px;
        text-align: center;
        line-height: 12px; }
        @media screen and (min-width: 960px) {
          .day-by-day-plot ul.axis.x-axis li span {
            white-space: nowrap; } }

.social-paragraph {
  background-color: #e9e3c2;
  padding: 1rem 0;
  margin: 6rem 0 0; }
  .social-paragraph .cols {
    margin: 2rem auto 2rem; }
  .social-paragraph p {
    font-family: 'Spectral', Serif;
    font-size: 2.2rem;
    font-weight: 800;
    line-height: 2.4rem;
    margin: 2rem 0; }
  .social-paragraph .social-buttons {
    display: flex; }
    .social-paragraph .social-buttons > * {
      margin-right: 2rem; }

.starring ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  .starring ul li {
    height: 100%;
    margin-bottom: 3rem; }
    .starring ul li .character-title {
      display: flex;
      align-items: center;
      flex-wrap: nowrap; }
      .starring ul li .character-title svg {
        min-width: 100px; }
      .starring ul li .character-title h2 {
        font-size: 2.4rem;
        line-height: 2.6rem;
        margin: 0;
        text-align: left; }
    @media screen and (min-width: 750px) {
      .starring ul li .character-description {
        padding-left: 100px; } }

ul.article-texts {
  margin: 0;
  margin-left: 50%;
  margin-top: 0;
  width: 100%;
  position: absolute;
  list-style: none;
  top: 0;
  z-index: 999999; }
  ul.article-texts .annotation p.text {
    color: #b1a979;
    font-style: italic;
    font-size: 10px;
    line-height: 12px;
    padding-bottom: 0; }
  ul.article-texts .annotation div.arrow > span {
    border-top-color: #b1a979; }
  @media (min-width: 768px) {
    ul.article-texts {
      margin-top: 0; }
      ul.article-texts .annotation p.text {
        width: 120px; } }
  ul.article-texts li.article-text {
    position: absolute;
    background-color: transparent;
    overflow: visible;
    width: 100%;
    bottom: 0;
    transform: translateY(-50%);
    text-shadow: var(--background--color) 1px 0 0, var(--background--color) 0.5403px 0.84147px 0, var(--background--color) -0.41615px 0.9093px 0, var(--background--color) -0.98999px 0.14112px 0, var(--background--color) -0.65364px -0.7568px 0, var(--background--color) 0.28366px -0.95892px 0, var(--background--color) 0.96017px -0.27942px 0;
    left: -50%;
    padding: 0 10px;
    margin-top: -5px; }
    @media (min-width: 768px) {
      ul.article-texts li.article-text {
        max-width: 350px;
        bottom: auto; } }
    ul.article-texts li.article-text > article {
      border-top: 3px solid #000;
      border-bottom: 1px solid #000;
      padding: 10px 0;
      background-color: rgba(255, 255, 255, 0.9);
      position: relative; }
      ul.article-texts li.article-text > article a.read-more {
        font-weight: 300;
        font-size: 11px;
        text-decoration: none;
        color: #000;
        display: inline-block;
        position: absolute;
        top: 12px;
        right: 10px;
        line-height: 1rem;
        text-transform: uppercase; }
        ul.article-texts li.article-text > article a.read-more:hover {
          background-color: transprent; }
    @media (min-width: 576px) {
      ul.article-texts li.article-text {
        padding: 0;
        margin-top: 0; }
        ul.article-texts li.article-text > article {
          border-top: none;
          border-bottom: none;
          padding: 0; }
        ul.article-texts li.article-text > article a.read-more {
          display: none; }
        ul.article-texts li.article-text::after {
          content: '';
          width: 20%;
          height: 1px;
          position: absolute;
          background-color: #000;
          top: 50%; }
        ul.article-texts li.article-text.small {
          overflow: visible; }
          ul.article-texts li.article-text.small::after {
            display: none; }
        ul.article-texts li.article-text.left-align {
          text-align: left; }
          ul.article-texts li.article-text.left-align > article {
            margin-left: 20%;
            border-left: 1px solid #000;
            padding-left: 10px; }
          ul.article-texts li.article-text.left-align ul.article-slugs {
            justify-content: flex-start; }
            ul.article-texts li.article-text.left-align ul.article-slugs li {
              margin-left: 0;
              margin-right: 1rem; }
        ul.article-texts li.article-text.right-align {
          text-align: right;
          transform: translate(-100%, -50%); }
          ul.article-texts li.article-text.right-align::after {
            right: 0; }
          ul.article-texts li.article-text.right-align > article {
            margin-right: 20%;
            border-right: 1px solid #000;
            padding-right: 10px; }
            ul.article-texts li.article-text.right-align > article h2 {
              text-align: right; }
        ul.article-texts li.article-text ul.article-tags {
          list-style: none;
          display: flex;
          justify-content: flex-end; }
          ul.article-texts li.article-text ul.article-tags li {
            padding: 0;
            margin: 0;
            text-align: left;
            margin-left: 1rem; } }
    ul.article-texts li.article-text ul.article-slugs {
      list-style: none;
      display: flex;
      justify-content: flex-start;
      font-weight: 300;
      font-size: 11px;
      margin: 0;
      margin-top: 1rem;
      padding: 0; }
      @media (min-width: 576px) {
        ul.article-texts li.article-text ul.article-slugs {
          justify-content: flex-end; } }
      ul.article-texts li.article-text ul.article-slugs li {
        padding: 0;
        padding-left: 1rem;
        margin: 0;
        text-align: left;
        margin-left: 1rem;
        text-transform: uppercase;
        white-space: nowrap;
        position: relative; }
        ul.article-texts li.article-text ul.article-slugs li::before {
          content: '';
          width: calc(1rem / 1.41);
          height: calc(1rem / 1.41);
          display: inline-block;
          position: absolute;
          background: #000;
          transform-origin: 0 0;
          transform: rotate(45deg);
          top: 2px;
          left: 0; }
        ul.article-texts li.article-text ul.article-slugs li.sinistra::before {
          background-color: #ba004e; }
        ul.article-texts li.article-text ul.article-slugs li.centro-sinistra::before {
          background-color: #F55B5B; }
        ul.article-texts li.article-text ul.article-slugs li.m5s::before {
          background-color: #FFDC73; }
        ul.article-texts li.article-text ul.article-slugs li.centro-destra::before {
          background-color: #56CCF2; }
        ul.article-texts li.article-text ul.article-slugs li.destra::before {
          background-color: #1E7AF7; }
        ul.article-texts li.article-text ul.article-slugs li.upa::before {
          background-color: var(--upa--color); }
        ul.article-texts li.article-text ul.article-slugs li.nda::before {
          background-color: var(--nda--color); }
        ul.article-texts li.article-text ul.article-slugs li.others::before {
          background-color: var(--others--color); }
        ul.article-texts li.article-text ul.article-slugs li.mahagathbandhan::before {
          background-color: var(--mahagathbandhan--color); }
        ul.article-texts li.article-text ul.article-slugs li.mahagathbandhan-yadav::before {
          background-color: var(--mahagathbandhan--color); }
        ul.article-texts li.article-text ul.article-slugs li.mahagathbandhan-mayawati::before {
          background-color: var(--mahagathbandhan--color); }
    ul.article-texts li.article-text h2 {
      margin: 0;
      padding: 0;
      display: block;
      position: relative;
      font-family: 'Spectral', Serif;
      font-size: 1.6rem;
      line-height: 1.7rem;
      font-weight: normal;
      color: #222;
      margin-top: 10px;
      text-align: left; }
      ul.article-texts li.article-text h2 a {
        display: inline;
        text-decoration: none;
        color: inherit;
        pointer-events: none;
        background-color: transparent; }
      ul.article-texts li.article-text h2 a:hover {
        text-decoration: underline;
        background-color: transparent; }
      @media (min-width: 768px) {
        ul.article-texts li.article-text h2 {
          font-size: 1.9rem;
          line-height: 2rem;
          font-weight: bold; }
          ul.article-texts li.article-text h2 a {
            pointer-events: auto; } }
    ul.article-texts li.article-text span {
      font-weight: 300;
      display: block;
      font-size: 11px; }
    ul.article-texts li.article-text span.article-source {
      text-transform: uppercase; }
    ul.article-texts li.article-text.hidden article {
      display: none; }
    ul.article-texts li.article-text.hidden::after {
      display: none; }
    ul.article-texts li.article-text.default h2, ul.article-texts li.article-text.highlight-article h2 {
      color: #000; }
    ul.article-texts li.article-text.default > article {
      display: block !important;
      background-color: rgba(255, 252, 235, 0.9); }
    ul.article-texts li.article-text.default::after {
      display: block !important; }
    ul.article-texts li.article-text.highlight-article {
      z-index: 9999; }
      ul.article-texts li.article-text.highlight-article > article {
        display: block !important;
        background-color: rgba(255, 252, 235, 0.9); }
      ul.article-texts li.article-text.highlight-article::after {
        display: block !important; }

#visualTimeline {
  pointer-events: none;
  z-index: 999;
  position: relative; }
  #visualTimeline ul.visual-timeline {
    padding: 0;
    list-style: none;
    top: 0;
    width: 100%; }
    @media (min-width: 768px) {
      #visualTimeline ul.visual-timeline {
        position: absolute; } }
    #visualTimeline ul.visual-timeline li {
      margin-bottom: 2rem;
      background-color: var(--background--color-alpha);
      text-shadow: var(--background--color-alpha) 1px 0 0, var(--background--color-alpha) 0.5403px 0.84147px 0, var(--background--color-alpha) -0.41615px 0.9093px 0, var(--background--color-alpha) -0.98999px 0.14112px 0, var(--background--color-alpha) -0.65364px -0.7568px 0, var(--background--color-alpha) 0.28366px -0.95892px 0, var(--background--color-alpha) 0.96017px -0.27942px 0; }
      @media (min-width: 768px) {
        #visualTimeline ul.visual-timeline li {
          max-width: 30%;
          position: absolute;
          line-height: 3rem; } }
      @media screen and (min-width: 992px) {
        #visualTimeline ul.visual-timeline li {
          max-width: 30%; } }
      #visualTimeline ul.visual-timeline li.intro {
        max-width: 100%; }
      #visualTimeline ul.visual-timeline li span {
        display: inline;
        padding: 0 5px 0 0; }
        #visualTimeline ul.visual-timeline li span span.politician-name {
          padding: 0;
          border-bottom: 3px solid #000; }
          #visualTimeline ul.visual-timeline li span span.politician-name::after {
            content: '';
            position: relative;
            display: inline-block;
            width: 1.6rem;
            height: 1.6rem;
            background: transparent center center no-repeat;
            background-size: contain;
            margin-left: 4px; }
          #visualTimeline ul.visual-timeline li span span.politician-name.narendra-modi::after {
            background-image: url(/static/media/narendra-modi.4e858512.svg); }
          #visualTimeline ul.visual-timeline li span span.politician-name.rahul-gandhi::after {
            background-image: url(/static/media/rahul-gandhi.205d10bc.svg); }
          #visualTimeline ul.visual-timeline li span span.politician-name.mayawati::after {
            background-image: url(/static/media/mayawati.98874f84.svg); }
          #visualTimeline ul.visual-timeline li span span.politician-name.akhilesh-yadav::after {
            background-image: url(/static/media/akhilesh-yadav.758a0aec.svg); }
          #visualTimeline ul.visual-timeline li span span.politician-name.mamata-banerjee::after {
            background-image: url(/static/media/mamata-banerjee.62148781.svg); }
        #visualTimeline ul.visual-timeline li span span.party-name {
          padding: 0;
          display: inline-block;
          border-bottom: 3px solid #000; }
          #visualTimeline ul.visual-timeline li span span.party-name.no-underline {
            border-bottom: none; }
          #visualTimeline ul.visual-timeline li span span.party-name::after {
            content: '';
            position: relative;
            display: inline-block;
            width: 0.8rem;
            height: 0.8rem;
            margin: 0px 0 1px 2px;
            border: 1px solid #000;
            background-color: #eee;
            overflow: hidden;
            text-indent: -2000px;
            transform-origin: 0, 0;
            transform: rotate(45deg);
            opacity: 1; }
          #visualTimeline ul.visual-timeline li span span.party-name.nda::after {
            background-color: var(--nda--color); }
          #visualTimeline ul.visual-timeline li span span.party-name.upa::after {
            background-color: var(--upa--color); }
          #visualTimeline ul.visual-timeline li span span.party-name.mayawati::after {
            background-color: var(--mayawati--color); }
      #visualTimeline ul.visual-timeline li h3 {
        font-weight: normal;
        font-size: 2.2rem;
        line-height: 2.2rem; }
      #visualTimeline ul.visual-timeline li.key-info {
        max-width: 20%; }
  #visualTimeline .square-scale {
    display: inline-block;
    position: relative; }
    #visualTimeline .square-scale::after, #visualTimeline .square-scale::before {
      content: '';
      transform-origin: 0, 0;
      transform: rotate(45deg);
      border: 2px solid #000;
      width: 2rem;
      height: 2rem;
      display: inline-block;
      position: relative;
      background-color: #eee; }
    #visualTimeline .square-scale.less::before {
      width: 1rem;
      height: 1rem;
      margin-right: 5px; }
    #visualTimeline .square-scale.less::after {
      display: none; }
    #visualTimeline .square-scale.more::after {
      width: 3rem;
      height: 3rem;
      margin-left: 5px; }
    #visualTimeline .square-scale.more::before {
      display: none; }
  #visualTimeline ul.diamond-chart {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    #visualTimeline ul.diamond-chart li {
      position: relative;
      display: inline-block;
      width: 11px;
      height: 11px;
      margin: 0 5px 5px 0;
      border: 1px solid #000;
      background-color: #eee;
      overflow: hidden;
      text-indent: -2000px;
      transform-origin: 0, 0;
      transform: rotate(45deg);
      opacity: 1; }
  #visualTimeline div.emoticons-stats {
    margin-top: 10vh; }
    #visualTimeline div.emoticons-stats > div {
      display: flex;
      justify-content: flex-start; }
      #visualTimeline div.emoticons-stats > div svg {
        margin-right: 10px; }
      #visualTimeline div.emoticons-stats > div span.stats-value {
        line-height: 43px;
        font-size: 3rem; }
        #visualTimeline div.emoticons-stats > div span.stats-value i {
          font-style: normal;
          font-weight: normal;
          font-size: 2rem; }

.squares-container {
  position: relative;
  padding-top: 0; }
  @media screen and (min-width: 768px) {
    .squares-container {
      padding-top: 0; } }
  .squares-container svg.squares-svg {
    min-height: 100vh; }
    .squares-container svg.squares-svg g circle {
      fill: #fff; }
    .squares-container svg.squares-svg g path.square {
      fill: #fff;
      stroke: #000;
      stroke-width: 2; }
    .squares-container svg.squares-svg g text {
      font-size: 10px;
      text-anchor: middle; }
    .squares-container svg.squares-svg g g.article use.mask02 {
      -webkit-clip-path: inset(-5px 21px -5px -5px);
              clip-path: inset(-5px 21px -5px -5px); }
    .squares-container svg.squares-svg g g.article use.mask12 {
      -webkit-clip-path: inset(-5px -5px -5px 21px);
              clip-path: inset(-5px -5px -5px 21px); }
    .squares-container svg.squares-svg g g.article use.mask03 {
      -webkit-clip-path: inset(-5px 28px -5px -5px);
              clip-path: inset(-5px 28px -5px -5px); }
    .squares-container svg.squares-svg g g.article use.mask13 {
      -webkit-clip-path: inset(-5px 14px -5px 14px);
              clip-path: inset(-5px 14px -5px 14px); }
    .squares-container svg.squares-svg g g.article use.mask23 {
      -webkit-clip-path: inset(-5px -5px -5px 28px);
              clip-path: inset(-5px -5px -5px 28px); }
    .squares-container svg.squares-svg g g.article use.mask04 {
      -webkit-clip-path: inset(-5px 31px -5px -5px);
              clip-path: inset(-5px 31px -5px -5px); }
    .squares-container svg.squares-svg g g.article use.mask14 {
      -webkit-clip-path: inset(-5px 21px -5px 11px);
              clip-path: inset(-5px 21px -5px 11px); }
    .squares-container svg.squares-svg g g.article use.mask24 {
      -webkit-clip-path: inset(-5px 11px -5px 21px);
              clip-path: inset(-5px 11px -5px 21px); }
    .squares-container svg.squares-svg g g.article use.mask34 {
      -webkit-clip-path: inset(-5px -5px -5px 31px);
              clip-path: inset(-5px -5px -5px 31px); }
    .squares-container svg.squares-svg g g.article.highlight-article path.square {
      stroke-width: 4; }
  .squares-container ul.timeline {
    font-size: 0.9rem;
    margin: 0;
    padding: 0;
    width: 100%;
    position: absolute;
    top: 0;
    margin-top: 0;
    list-style: none;
    pointer-events: none; }
    @media screen and (min-width: 768px) {
      .squares-container ul.timeline {
        margin-top: 0;
        font-size: 1.3rem; } }
    .squares-container ul.timeline li {
      position: absolute;
      right: 0;
      font-size: inherit;
      color: #888;
      width: 100px;
      height: 14px; }
      .squares-container ul.timeline li::before {
        content: '';
        position: absolute;
        border-bottom: 1px solid #ddd;
        right: 0;
        width: 10px; }
      .squares-container ul.timeline li span {
        position: absolute;
        display: inline-block;
        padding: 1px;
        width: calc(100% - 15px);
        text-align: right;
        transform: translateY(-50%); }
  .squares-container ul.links {
    margin: 0;
    padding: 0;
    width: calc(100% - 5px);
    position: absolute;
    list-style: none;
    top: 0;
    left: 0; }
    .squares-container ul.links li {
      position: absolute;
      border-top: 1px solid #fff;
      background-color: rgba(255, 255, 0, 0.2);
      transform-origin: 0 0; }
  .squares-container .content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }

@keyframes spin {
  100% {
    transform: rotate(360deg); } }

.faces-container {
  width: 100%;
  margin: 0 auto; }
  .faces-container .faces-parallax {
    position: relative;
    width: 100%;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; }
    @media screen and (min-width: 750px) {
      .faces-container .faces-parallax section#visualTimeline {
        top: 100%;
        display: block; } }
  .faces-container .show-more {
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    margin: 150px auto 20px;
    outline: none;
    position: relative;
    width: 141px;
    height: 100px; }
    @media screen and (min-width: 576px) {
      .faces-container .show-more {
        margin: 80px auto 20px; } }
    .faces-container .show-more span {
      display: block;
      text-align: center;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%); }
    .faces-container .show-more::before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 80px;
      height: 80px;
      border: 2px solid #000;
      background-color: #eee;
      left: 50%;
      top: 50%;
      transform-origin: -50%, -50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transition-duration: 0.8s;
      transition-property: transform;
      transition-timing-function: ease;
      opacity: 1; }
    .faces-container .show-more:hover span {
      color: #fff; }
    .faces-container .show-more:hover::before {
      background-color: var(--link--color);
      border: 3px solid #000; }

.FacesContainer {
  margin-top: 8rem;
  overflow-x: hidden;
  overflow-y: visible; }
  @media screen and (min-width: 960px) {
    .FacesContainer {
      margin-top: 15rem; } }
  .FacesContainer figure {
    margin: 0;
    padding: 0; }
  .FacesContainer span.party-name {
    padding: 0;
    display: inline-block;
    border-bottom: 3px solid #000; }
    .FacesContainer span.party-name.no-underline {
      border-bottom: none; }
    .FacesContainer span.party-name::after {
      content: '';
      position: relative;
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      margin: 0px 0 1px 2px;
      border: 1px solid #000;
      background-color: #eee;
      overflow: hidden;
      text-indent: -2000px;
      transform-origin: 0, 0;
      transform: rotate(45deg);
      opacity: 1; }
    .FacesContainer span.party-name.medium::after {
      width: 1.1rem;
      height: 1.1rem; }
    .FacesContainer span.party-name.large::after {
      width: 1.4rem;
      height: 1.4rem; }
    .FacesContainer span.party-name.nda::after {
      background-color: var(--nda--color); }
    .FacesContainer span.party-name.upa::after {
      background-color: var(--upa--color); }
    .FacesContainer span.party-name.mayawati::after {
      background-color: var(--mayawati--color); }
  .FacesContainer span.politician-name {
    padding: 0;
    border-bottom: 3px solid #000; }
    .FacesContainer span.politician-name::after {
      content: '';
      position: relative;
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      background: transparent center center no-repeat;
      background-size: contain;
      margin-left: 4px; }
    .FacesContainer span.politician-name.narendra-modi::after {
      background-image: url(/static/media/narendra-modi.4e858512.svg); }
    .FacesContainer span.politician-name.rahul-gandhi::after {
      background-image: url(/static/media/rahul-gandhi.205d10bc.svg); }
    .FacesContainer span.politician-name.mayawati::after {
      background-image: url(/static/media/mayawati.98874f84.svg); }
    .FacesContainer span.politician-name.akhilesh-yadav::after {
      background-image: url(/static/media/akhilesh-yadav.758a0aec.svg); }
    .FacesContainer span.politician-name.mamata-banerjee::after {
      background-image: url(/static/media/mamata-banerjee.62148781.svg); }

