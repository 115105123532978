.CompareCandidates .CompareCandidates--charts {
    display: block;
    margin: 0 auto;
    max-width: 160rem;
    width: 100%;
}

@media screen and (min-width: 960px) {
    .CompareCandidates .CompareCandidates--charts {
        display: flex;
        flex-wrap: wrap;
    }
}

.CompareCandidates .CompareCandidates--candidate {
    display: block;
    padding: 1rem 0;
    position: relative;
}

@media screen and (min-width: 960px) {
    .CompareCandidates .CompareCandidates--candidate {
        flex: 0 0 33%;
        padding: 1rem 2rem 2rem 2rem;
    }
}

.CompareCandidates .CompareCandidates--chart {
    box-shadow: inset 0 4rem 4.2rem -4.2rem rgba(0, 0, 0, .1), inset 0 -4rem 4.2rem -4.2rem rgba(0, 0, 0, .1);
    border: 1px solid rgba(0, 0, 0, .1);
    height: 17.5rem;
    margin: 1rem 0 0 0;
    padding: 0 0 .5rem 0;
    position: relative;
    width: 100%;
}

.CompareCandidates h3 {
    font-size: 1.4rem;
    margin-top: 0;
    text-align: center;
}