@import "../../variables.scss";

#visualTimeline {
  pointer-events: none;
  z-index: 999;
  position: relative;

  ul.visual-timeline {
    padding: 0;
    list-style: none;
    top: 0;
    width: 100%;

    @media (min-width: 768px) {
      position: absolute;
    }

    li {
      margin-bottom: 2rem;
      background-color: var(--background--color-alpha);

      @media (min-width: 768px) {
        max-width: 30%;
        position: absolute;
        line-height: 3rem;
      }
      @media screen and (min-width: 992px) {
        max-width: 30%;
      }

      // background-color: rgba(255,255,255,0.5);

      @include shadowAlpha();

      &.intro {
        max-width: 100%;
      }
      span {
        // background-color: #fff;
        display: inline;
        padding: 0 5px 0 0;

        span.politician-name {
          padding: 0;
          // display: inline-block;
          border-bottom:3px solid #000;

          &::after {
            content: '';
            position: relative;
            display: inline-block;
            width: 1.6rem;
            height: 1.6rem;
            background: transparent center center no-repeat;
            background-size: contain;
            margin-left: 4px;
          }

          &.narendra-modi::after {
            background-image: url('../../emoticons/narendra-modi.svg');
          }

          &.rahul-gandhi::after {
            background-image: url('../../emoticons/rahul-gandhi.svg');
          }

          &.mayawati::after {
            background-image: url('../../emoticons/mayawati.svg');
          }

          &.akhilesh-yadav::after {
            background-image: url('../../emoticons/akhilesh-yadav.svg');
          }

          &.mamata-banerjee::after {
            background-image: url('../../emoticons/mamata-banerjee.svg');
          }


        }

        span.party-name {
          padding: 0;
          display: inline-block;
          border-bottom:3px solid #000;

          &.no-underline {
            border-bottom: none;
          }

          &::after {
            content: '';
            position: relative;
            display: inline-block;
            width: 0.8rem;
            height: 0.8rem;
            margin: 0px 0 1px 2px;
            border: 1px solid #000;
            background-color: #eee;
            overflow: hidden;
            text-indent: -2000px;

            transform-origin: 0, 0;
            transform: rotate(45deg);

            opacity: 1;
          }

          &.nda::after {
            background-color: var(--nda--color);
          }

          &.upa::after {
            background-color: var(--upa--color);
          }

          &.mayawati::after {
            background-color: var(--mayawati--color);
          }

        }

      }

      h3 {
        font-weight: normal;
        font-size: 2.2rem;
        line-height: 2.2rem;
      }

      &.key-info {
        max-width: 20%;
      }
    }
  }

  .square-scale {
    display: inline-block;
    position: relative;
    &::after,
    &::before {
      content: '';
      transform-origin: 0, 0;
      transform: rotate(45deg);
      border: 2px solid #000;
      width: 2rem;
      height: 2rem;
      display: inline-block;
      position: relative;
      background-color: #eee;
    }

    &.less {
      &::before {
        width: 1rem;
        height: 1rem;
        margin-right: 5px;
      }
      &::after {
        display: none;
      }
    }

    &.more {
      &::after {
        width: 3rem;
        height: 3rem;
        margin-left: 5px;
      }
      &::before {
        display: none;
      }
    }
  }



  ul.diamond-chart {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    // width: 200px;
    li {
      position: relative;
      display: inline-block;
      width: 11px;
      height: 11px;
      margin: 0 5px 5px 0;
      border:1px solid #000;
      background-color: #eee;
      overflow: hidden;
      text-indent: -2000px;

      transform-origin: 0, 0;
      transform: rotate(45deg);

      opacity: 1;
    }
  }

  div.emoticons-stats {
    margin-top: 10vh;
    >div {
      display: flex;
      justify-content: flex-start;
      svg {
        margin-right: 10px;
      }
      span.stats-value {
        line-height: 43px;
        font-size: 3rem;

        i {
          font-style: normal;
          font-weight: normal;
          font-size: 2rem;
        }
      }
    }
  }
}
