.social-paragraph {
  background-color: #e9e3c2;
  // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  margin: 6rem 0 0;
  .cols {
    margin: 2rem auto 2rem;
  }
  p {
    font-family: 'Spectral', Serif;
    font-size: 2.2rem;
    font-weight: 800;
    line-height: 2.4rem;
    margin: 2rem 0;
  }

  .social-buttons {
    display: flex;
    > * {
      margin-right: 2rem;
    }
  }
}
