// @import url("https://fonts.googleapis.com/css?family=Zilla+Slab:700|Nunito+Sans:300,300i,400,400i|Playfair+Display:400,400i,700,700i|Crimson+Text:400,400i,600,600i,700,700i&amp;subset=latin-ext");

.FacesContainer {
  margin-top: 8rem;
  overflow-x: hidden;
  overflow-y: visible;

  @media screen and (min-width: 960px) {
    margin-top: 15rem;
  }

  figure {
    margin: 0;
    padding: 0; // 60px 0 50px;
  }

  span.party-name {
    padding: 0;
    display: inline-block;
    border-bottom:3px solid #000;

    &.no-underline {
      border-bottom: none;
    }

    &::after {
      content: '';
      position: relative;
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      margin: 0px 0 1px 2px;
      border: 1px solid #000;
      background-color: #eee;
      overflow: hidden;
      text-indent: -2000px;

      transform-origin: 0, 0;
      transform: rotate(45deg);

      opacity: 1;
    }

    &.medium {
      &::after {
        width: 1.1rem;
        height: 1.1rem;
      }
    }

    &.large {
      &::after {
        width: 1.4rem;
        height: 1.4rem;
      }
    }

    &.nda::after {
      background-color: var(--nda--color);
    }

    &.upa::after {
      background-color: var(--upa--color);
    }

    &.mayawati::after {
      background-color: var(--mayawati--color);
    }

  }

  span.politician-name {
    padding: 0;
    // display: inline-block;
    border-bottom:3px solid #000;

    &::after {
      content: '';
      position: relative;
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      background: transparent center center no-repeat;
      background-size: contain;
      margin-left: 4px;
    }

    &.narendra-modi::after {
      background-image: url('./emoticons/narendra-modi.svg');
    }

    &.rahul-gandhi::after {
      background-image: url('./emoticons/rahul-gandhi.svg');
    }

    &.mayawati::after {
      background-image: url('./emoticons/mayawati.svg');
    }

    &.akhilesh-yadav::after {
      background-image: url('./emoticons/akhilesh-yadav.svg');
    }

    &.mamata-banerjee::after {
      background-image: url('./emoticons/mamata-banerjee.svg');
    }


  }
}
