@import "../../variables.scss";

.squares-container {
  position: relative;
  //padding-top: 0;
  padding-top: $paddingTop;
  @media screen and (min-width: 768px) {
    padding-top: $paddingTop;
  }
  svg.squares-svg {
    min-height: 100vh;
    g {
      circle {
        fill:#fff;
      }
      path.square {
        fill:#fff;
        stroke: #000;
        stroke-width: 2;
      }
      text {
        font-size: 10px;
        text-anchor: middle;
      }
      g.article {
        use.mask02 {
          clip-path: inset(-5px 21px -5px -5px)
        }
        use.mask12 {
          clip-path: inset(-5px -5px -5px 21px)
        }

        use.mask03 {
          clip-path: inset(-5px 28px -5px -5px)
        }
        use.mask13 {
          clip-path: inset(-5px 14px -5px 14px)
        }
        use.mask23 {
          clip-path: inset(-5px -5px -5px 28px)
        }

        use.mask04 {
          clip-path: inset(-5px 31px -5px -5px)
        }
        use.mask14 {
          clip-path: inset(-5px 21px -5px 11px)
        }
        use.mask24 {
          clip-path: inset(-5px 11px -5px 21px)
        }
        use.mask34 {
          clip-path: inset(-5px -5px -5px 31px)
        }
      }
      g.article.highlight-article {
        path.square {
          stroke-width: 4;
        }
      }
    }
  }

  ul.timeline {
    font-size: 0.9rem;
    margin: 0;
    padding: 0;
    width: 100%;
    position: absolute;
    top: 0;
    margin-top: $paddingTop;
    list-style: none;
    pointer-events: none;

    @media screen and (min-width: 768px) {
      margin-top: $paddingTop;
      font-size: 1.3rem;
    }

    li {
      position: absolute;
      right: 0;
      font-size: inherit;
      color: #888;
      width: 100px;
      height: 14px;

      &::before {
        content: '';
        position: absolute;
        border-bottom: 1px solid #ddd;
        right: 0;
        width: 10px;
      }

      span {
        position: absolute;
        display: inline-block;
        padding: 1px;
        width: calc(100% - 15px);
        text-align: right;
        transform: translateY(-50%);
      }
    }
  }

  ul.links {
    margin: 0;
    padding: 0;
    width: calc(100% - 5px);
    position: absolute;
    list-style: none;
    top: 0;
    left: 0;
    li {
      position: absolute;
      border-top: 1px solid #fff;
      background-color: rgba(255,255,0,0.2);
      transform-origin: 0 0;
    }
  }

  .content-overlay {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
  }
}
