.LineChart {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.LineChart svg .line {
  fill: none;
  /* mix-blend-mode: multiply; */
  opacity: .2;
  stroke: rgb(68, 68, 68);
  stroke-dasharray: none;
  stroke-width: 1px;
}

.LineChart svg .line.selected {
  fill: none;
  opacity: 1;
  stroke: var(--highlight--color);
  stroke-dasharray: none;
  stroke-width: 2px;
}

.LineChart svg .xAxis {
  /* mix-blend-mode: multiply; */
  stroke: rgba(0, 0, 0, .1);
  stroke-dasharray: none;
  stroke-width: 1px;
}

.LineChart svg .dateLine {
  /* mix-blend-mode: multiply; */
  stroke: rgba(0, 0, 0, .25);
  stroke-dasharray: 1 2;
  stroke-width: 1px;
}

.LineChart svg .label {
  fill: rgba(0, 0, 0, .75);
  font-size: 10px;
}

.LineChart svg .bullet {
  /* mix-blend-mode: multiply; */
  fill: rgba(0, 0, 0, .25);
}

.LineChart .annotation {
  background-color: rgb(255, 252, 235, 0.5);
  border-radius: 2rem;
  display: none;
  height: auto;
  min-width: 20rem;
  opacity: 0;
  padding: 0;
  position: absolute;
  transform: translate(0,0);
  transition: opacity .5s ease-in-out;
  width: auto;
  z-index: 9;
}

.LineChart .visible.annotation {
  display: block;
}

.LineChart .visible.animate.annotation {
  opacity: 1;
}

.LineChart .annotation article {
  display: block;
  height: auto;
  position: relative;
  width: auto;
}

.LineChart .annotation.top article {
  margin-bottom: -.75rem;
}

.LineChart .annotation.bottom article {
  margin-top: -.75rem;
}

.LineChart .annotation.right.bottom {
  padding-right: 4rem;
  transform: translate(-100%,0);
}

.LineChart .annotation.right.top {
  padding-right: 4rem;
  transform: translate(-100%,-100%);
}

.LineChart .annotation.left.bottom {
  padding-left: 4rem;
  transform: translate(0,0);
}

.LineChart .annotation.left.top {
  padding-left: 4rem;
  transform: translate(0,-100%);
}

.LineChart .annotation.right.bottom:after {
  background-color: var(--text--color);
  content: '';
  display: block;
  height: .1rem;
  position: absolute;
  right:  1rem;
  top: -.1rem;
  transform-origin: center center;
  width: 2.5rem;
}

.LineChart .annotation.right.top:after {
  background-color: var(--text--color);
  bottom: -.1rem;
  content: '';
  display: block;
  height: .1rem;
  position: absolute;
  right:  1rem;
  transform-origin: center center;
  width: 2.5rem;
}

.LineChart .annotation.left.bottom:after {
  background-color: var(--text--color);
  content: '';
  display: block;
  height: .1rem;
  position: absolute;
  left:  1rem;
  top: 0;
  transform-origin: center center;
  width: 2.5rem;
}

.LineChart .annotation.left.top:after {
  background-color: var(--text--color);
  bottom: -.1rem;
  content: '';
  display: block;
  height: .1rem;
  position: absolute;
  left:  1rem;
  transform-origin: center center;
  width: 2.5rem;
}

.LineChart .annotation a,
.LineChart .annotation a:hover,
.LineChart .annotation a:active,
.LineChart .annotation a h3,
.LineChart .annotation a h2 {
  background-color: transparent;
  box-shadow: none;
  color: var(--text--color);
  font-family: 'Work Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.25;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-shadow: var(--background--color) 1px 0 0,
    var(--background--color) 0.540302px 0.841471px 0,
    var(--background--color) -0.416147px 0.909297px 0,
    var(--background--color) -0.989992px 0.14112px 0,
    var(--background--color) -0.653644px -0.756802px 0,
    var(--background--color) 0.283662px -0.958924px 0,
    var(--background--color) 0.96017px -0.279415px 0;
}



.LineChart .annotation.right a,
.LineChart .annotation.right a:hover,
.LineChart .annotation.right a:active,
.LineChart .annotation.right a h3,
.LineChart .annotation.right a h2 {
  text-align: right;
}

@keyframes lens {
  from {
    box-shadow: 0 0 0 2px rgba(229, 111, 135, 0), 0 0 0 5px rgba(229, 111, 135, 0), 0 0 0 9px rgba(229, 111, 135, 0);
  }
  to {
    box-shadow: 0 0 0 2px rgba(229, 111, 135, .4), 0 0 0 5px rgba(229, 111, 135, .2), 0 0 0 9px rgba(229, 111, 135, .1);
  }
}


.LineChart .lens {
  background: transparent;
  border: .1rem solid var(--text--color);
  border-radius: .2rem;
  cursor: pointer;
  display: block;
  height: 1rem;
  position: absolute;
  transform-origin: center center;
  transform: translate3d(-50%,-50%,0) rotate(45deg);
  width: 1rem;
}

.LineChart .lens.animate {
  animation-name: lens;
  animation-duration: 4.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-fill-mode:  both;
  animation-play-state: running;
}
