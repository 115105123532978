.States .States--charts {
    display: block;
    margin: 0 auto;
    max-width: 160rem;
    width: 100%;
}

@media screen and (min-width: 960px) {
    .States .States--charts {
        display: flex;
        flex-wrap: wrap;
    }
}

.States .States--state {
    display: none;
    padding: 1rem 0;
    position: relative;
}

.States .States--state.Maharashtra,
.States .States--state.Karnataka,
.States .States--state.JammuandKashmir,
.States .States--state.UttarPradesh,
.States .States--state.WestBengal,
.States .States--state.Delhi {
  display: block;
}

.States--charts.show-all .States--state {
  display: block;
}

@media screen and (min-width: 960px) {
    .States .States--state {
        flex: 0 0 33.33%;
        padding: 1rem 2rem 2rem 2rem;
    }
}

.States .States--chart {
    box-shadow: inset 0 4rem 4.2rem -4.2rem rgba(0, 0, 0, .1), inset 0 -4rem 4.2rem -4.2rem rgba(0, 0, 0, .1);
    border: 1px solid rgba(0, 0, 0, .1);
    height: 20rem;
    margin: 1rem 0 0 0;
    padding: 0 0 .5rem 0;
    position: relative;
    width: 100%;
}

.States h3 {
    font-size: 1.4rem;
    margin-top: 0;
    text-align: center;
}

.States .States--show-more {
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin: 100px auto 100px auto;
  outline: none;
  position: relative;
  width: 141px;
  height: 100px;
}

.States .States--show-more span {
  display: block;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.States .States--show-more:before {
  background-color: #eee;
  border: 2px solid #000;
  content: '';
  display: inline-block;
  height: 80px;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: -50%, -50%;
  transition-duration: 0.8s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease;
  width: 80px;
}

.States .States--show-more:hover:before {
  background-color: var(--link--color);
  border: 3px solid #000;
}

.States .States--show-more:hover span {
  color:#fff;
}
