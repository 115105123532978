@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.faces-container {
  width: 100%;
  margin: 0 auto;

  .faces-parallax {
    position: relative;
    width: 100%;

    // perspective: 2px;
    // transform-style: preserve-3d;
    // height: 100vh;
    // overflow-y: auto;
    // overflow-x: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    // .parallax-layer {
    //   position: relative;
    // }

    // .parallax-layer {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    // }
    //
    // .parallax-layer-base {
    //   transform: translateZ(0);
    // }
    // .parallax-layer-back {
    //   //transform: translateZ(-1px) scale(2); // scale = 1 + (translateZ * -1) / perspective
    //   transform: translateZ(1px) scale(0.5); // scale = 1 + (translateZ * -1) / perspective
    //   transform-origin: 50% 0;
    // }


    @media screen and (min-width: 750px) {
      section#visualTimeline {
        top: 100%;
        display: block;
      }
    }
  }

  .show-more {
    font-size: 12px;
    text-align: center;
    // border-radius: 25px;
    cursor: pointer;
    margin: 150px auto 20px;
    outline: none;
    @media screen and (min-width: 576px) {
      margin: 80px auto 20px;
    }
    position: relative;
    width: 141px;
    height: 100px;

    span {
      display: block;
      text-align: center;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 80px;
      height: 80px;
      border: 2px solid #000;
      background-color: #eee;

      left: 50%;
      top: 50%;
      // transform: translate(-50%,-50%);
      transform-origin: -50%, -50%;
      transform: translate(-50%, -50%) rotate(45deg);

      transition-duration: 0.8s;
      transition-property: transform;
      transition-timing-function: ease;

      opacity: 1;
    }

    &:hover {
      span {
        color: #fff;
      }
      &::before {
        background-color: var(--link--color);
        border: 3px solid #000;
        // transform: translate(-50%,-50%) rotate(135deg);
      }
    }
  }
}
