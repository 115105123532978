.interactive-map {
  padding: 0;


  width: 100%;
  margin-left: 0;

  @media screen and (min-width: 568px) {
    display: flex;
    width: calc(100% - 8rem);
    margin-left: 4rem;

    >div {
      flex: 1 1 50%;
    }

  }

  .state-description {
    h2 {
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;
      font-size: 2rem;
      line-height: 2.2rem;
      display: none;
    }
    h3 {
      text-align: left;
      margin: 0;
      margin-top: 2rem;
    }
    ul {
      display: none;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    @media screen and (min-width: 568px) {
      padding-left: 3rem;
      h2 {
        margin-bottom: 2rem;
        font-size: 3rem;
        line-height: 3.2rem;
        display: inline-block;
      }
      ul {
        display: block;
      }
    }
  }

  .note {
    margin-top: 2rem;
    font-size: 11px;
    display: inline-block;
    color: #333;

    &.large {
      display: none;
    }
    @media screen and (min-width: 568px) {
      &.large {
        display: inline-block;
      }
      &.small {
        display: none;
      }
    }
  }
}
